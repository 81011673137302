import { forwardRef, useEffect, useImperativeHandle } from "react"
import { CobaFinancialAidPageProps } from "../cobaFinancialAidPageProps";
import usePageSignature from "../../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../../models/Forms.enum";

export const FinancialAidPage2 = forwardRef(({ data, visible }: CobaFinancialAidPageProps, ref) => {
    const { saveForm, getSignature, getAcknowledge } = usePageSignature(data, true);
    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(COBAFormsPage.FinancialAid2);
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="financialAid-page2">
            <div className="title">
                <h3>Student Acknowledgment, Information Release Authorization,</h3>
            </div>
            <div className="subtitle">
                <h4>Statement Of Certification And Educational Purpose</h4>
            </div>

            <p>Coba Academy (Coba) does not discriminate on the basis of race, color, religion, national or ethnic origin, gender, sexual orientation, age, or disability in the administration of its educational policies, admission policies, grant and loan programs, or other Academy administered rights, privileges, programs, or activities generally accorded or made available to students at Coba. Students must maintain good academic standing to be eligible for all federal financial aid funds.</p>

            <p>All students must read each of the following statements and sign below. If the statement appears to be inapplicable, please state the reason, and then sign.</p>

            <ol>
                <li>I certify that (a) I do not owe a refund on any educational grant programs; (b) I am not in default on any loan received under Federal Perkins program or other types such as the Federal Direct Loan (DL) and/or the Federal Family Education Loan (FFEL) programs; and (c) if I have, I have already made satisfactory payment arrangements on any loan that was in default. In addition, I understand that I have the right to access the NSLDS Student Access website <a href="https://studentaid.gov">https://studentaid.gov</a> to make inquiries about my Title IV records on loans and/or grants.</li>
                <li> have read the requirements to obtain financial aid at Coba. I certify that it is my obligation to apply for all loans, Pell in a timely manner. If I have any questions regarding my financial aid awards, I must contact the Office of Financial Aid for clarification. I understand that failure to do so may require me to obtain funds from another sourced (i.e., cash payments) to meet my financial obligation to Coba.</li>
                <li>I certify that during enrollment, I must maintain Satisfactory Academic Progress as defined by Coba policies for good academic standing. Failure to do so will result in the loss of eligibility to receive financial aid for future terms of enrollment at Coba (and/or academic disqualification).</li>
                <li>If my enrollment status changes (i.e., drop, withdraw, leave of absence, academic disqualification), I must contact the Office of Financial Aid about my status. I understand that adjustments will be made on my eligible awards, and any remaining excess aid funds will be returned to the original funding source. It is also my responsibility to inform the Office of Financial Aid of any changes to the number of clock hours that I am enrolled in for any given term during the academic year.</li>
                <li>I authorize the Office of Financial Aid to process my financial aid award funds via electronic fund transfer (EFT) for all payments related to tuition and required fees during my enrollment at Coba.</li>
                <li>By signing this document, I certify that I am the individual signing this Statement of Certification and Educational Purpose. Federal student financial aid funds that I may receive will be used only for educational purposes such as to pay for the cost of direct or indirect of attending at Coba.</li>
                <li>I hereby give permission to Coba to release my student information to appropriate agencies, organizations, corporations, and/or persons during any of Coba Academy’s business or affairs for purposes of awarding any external scholarships or other financial aid awards.</li>
            </ol>
            <div className="row acknowledge">
                {getAcknowledge("ACK-P2","I acknowledge that I have read and understand the above statements.")}
            </div>

            <div className="row">
                {getSignature()}
            </div>
        </div>
    )
});