import studentService from "../services/student.service"
import schoolService from "../services/school.service";
import { Student } from '../models/Student.model';
import { School } from '../models/School.model';
import { CacheConstants } from '../services/constants.service';
import documentService from "../services/document.service";
import { DocumentType } from "../models/Document.model";
import calendarService from '../services/calendar.service';
import { EventType } from '../models/Event.model';

/** Functions to load and process data in a shared location */
export interface IDataLoaders {
    studentsAndSchools: () => Promise<{ students: Student[], schools: School[] }>
    schools: () => Promise<School[]>
    documentTypes: () => Promise<DocumentType[]>
    eventTypes: () => Promise<EventType[]>
}
export default class DataLoaders implements IDataLoaders {
    constructor(private getWithCache: any) { }


    studentsAndSchools = async () => {
        let studentsPromise = this.getWithCache(CacheConstants.STUDENTS, studentService.getStudents);
        let schoolsPromise = this.getWithCache(CacheConstants.SCHOOLS, schoolService.getSchools);

        let [students, schools] = await Promise.all([studentsPromise, schoolsPromise]);

        // set school info to student object
        students?.forEach((student: Student) => {
            student.schoolData = schools.find((x: School) => x.id === student.school)
        });

        return { students, schools };
    }

    schools = async () => {
        return await this.getWithCache(CacheConstants.SCHOOLS, schoolService.getSchools);
    }

    documentTypes = async () => {
        return await this.getWithCache(CacheConstants.DOCUMENT_TYPES, documentService.getDocumentTypes, (response: any) => response.data);
    }

    eventTypes = async () => {
        return await this.getWithCache(CacheConstants.EVENT_TYPES, calendarService.getEventTypes, (response: any) => response.data);
    }


}