import React, { useContext, useState, useEffect } from "react";
import globalContext from "../../context/globalContext";
import SchoolPicker from "../../components/programs/schoolPicker/schoolPicker";
import { School } from "../../models/School.model";
import "./programsPage.scss";
import TitleBar from "../../components/shared/titleBar/titleBar";
import { Program, ProgramStartDate } from "../../models/Program.model";
import CardProgramView from "../../components/programs/program/programView/cardProgramView";
import CardStartDates from "../../components/programs/program/startDates/cardStartDates";
import CardScheduleView from "../../components/programs/program/cardSheduleView/cardScheduleView";

const ProgramsPage = (props: any) => {
  const { setLoading, loaders } = useContext(globalContext);
  const [schools, setSchools] = useState([] as School[]);
  const [selectedSchool, setSelectedSchool] = useState({} as School);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({} as Program);


  const loadData = async () => {
    setLoading(true);
    let schoolsRes = await loaders.schools()
    setLoading(false);

    setSchools(schoolsRes);

    // If the user has more than one school show the picker otherwise set the selectedSchool
    if (schoolsRes.length == 1) {
      setSelectedSchool(schoolsRes[0]);
    } else {
      setShowPicker(true);
    }
  };

  const handleShowPicker = () => {
    setShowPicker(!showPicker);
  };

  const handleSchoolChange = (option: any) => {
    setSelectedSchool(option);
    setShowPicker(false);
  };


  const handleSelectProgram = (program: Program) => {
    if (selectedProgram.id == program.id) {
      setSelectedProgram({} as Program);
    } else {
      setSelectedProgram(program);
    }
  };


  useEffect(() => {    
    loadData();
  }, []);

  return (
    <div className="home-page pd-0">
      <TitleBar header="Manage Programs" subheader="Presented on application form" />

      <SchoolPicker onChange={handleSchoolChange} schoolChoices={schools} showPicker={showPicker} setShowPicker={handleShowPicker}></SchoolPicker>

      <div className="programViewer">

        <div className="programWrapper">
          <CardProgramView
            selectedSchool={selectedSchool}
            schoolsLength={schools.length}
            showPicker={showPicker}
            selectedProgram={selectedProgram}
            setSelectedProgram={setSelectedProgram}
            handleSelectProgram={handleSelectProgram}
            handleShowPicker={handleShowPicker}
          />

          <CardStartDates
            selectedSchool={selectedSchool}
            selectedProgram={selectedProgram}
          />

          <CardScheduleView
            selectedSchool={selectedSchool}
            selectedProgram={selectedProgram}
          />
        </div>
      </div>

    </div>
  );
};

export default ProgramsPage;
