import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { Student } from "../../../../../models/Student.model";
import usePageSignature from "../../../../../hooks/pageSignature";
import commonService from "../../../../../services/common.service";
import { COBAFormsPage } from "../../../../../models/Forms.enum";
import TextField from "../../../../../components/shared/Input/textField";
import SelectField from "../../../../../components/shared/Input/selectField";
import { MASKS } from "../../../../../components/shared/Input/patterns";
import PhoneField from "../../../../../components/shared/Input/phoneField/phoneField";
import { CobaExitCounselingPageProps } from "../coba.exitCounseling.pageProps";
import { SignedFormStudent } from "../../../../../models/Forms.model";
import '../cobaExitCounseling.scss';

interface ExtraData {
   driverLicense: string;
   issuingState: string;
   expectedEmployer: string;
   employerStreet: string;
   employerCity: string;
   employerState: string;
   employerZip: string;
   employerCountry: string;
   employerPhone: string;

   relativeFirstName: string;
   relativeLastName: string;
   relativeMiddleName: string;
   relativeStreet: string;
   relativeCity: string;
   relativeState: string;
   relativeZip: string;
   relativeCountry: string;
   relativePhone: string;
   relativeEmail: string;
}

interface ExitCounselingForm {
   student: Student;
   extraData: ExtraData;
};


export const ExitCounselingPage1 = forwardRef(({ data, visible }: CobaExitCounselingPageProps, ref) => {
   const { register, formState: { errors, isValid }, getValues, watch, trigger, control, setValue } = useForm<ExitCounselingForm>();
   const { saveForm, getInitials, validateForm, setIsCustomValid, setRequiredCustomValidation, initialsData } = usePageSignature(data, false);

   const [states, setStates] = useState<any[]>([]);
   const [student, setStudent] = useState<SignedFormStudent>(null!);
   const [isDriverLicenseRequired, setIsDriverLicenseRequired] = useState(false);
   const [isExpectedEmployer, setIsExpectedEmployer] = useState(false);

   const loadStates = async () => {
      const states = await commonService.getStates();
      setStates(states);
   }

   useImperativeHandle(ref, () => ({
      async saveData() {
         let data: any = getValues().extraData;
         let extraData: any = {};
         Object.keys(data).forEach((key) => {
            if (data[key] !== "" && data[key] !== null && data[key] !== undefined) {
               extraData = { ...extraData, [key]: data[key] };
            }
         });
         return await saveForm(COBAFormsPage.ExitConseling1, false, extraData);
      }
   }));

   const formValues = watch();
   const driverLicenceData = watch("extraData.driverLicense");
   const expectedEmployerData = watch("extraData.expectedEmployer");

   useEffect(() => {
      if (driverLicenceData) {
         setIsDriverLicenseRequired(true);
      } else {
         setIsDriverLicenseRequired(false);
      }
   }, [driverLicenceData]);

   useEffect(() => {
      if (expectedEmployerData) {
         setIsExpectedEmployer(true);
      } else {
         setIsExpectedEmployer(false);
      }
   }, [expectedEmployerData]);

   useEffect(() => {
      setIsCustomValid(isValid);
   }, [formValues]);

   useEffect(() => {
      manualFormValidation();
   }, [initialsData]);

   const manualFormValidation = async () => {
      if (initialsData) {
         const isItValidNow = await trigger();
         if (!isItValidNow) {
            setInterval(() => {
               trigger();
            }, 1000);
         }
      }
   };

   useEffect(() => {
      setRequiredCustomValidation(true); // the form required setIsCustomValid to be called with true
      setValue("student.firstName", data.formData.student.firstName);
      setValue("student.middleName", data.formData.student.middleName!);
      setValue("student.lastName", data.formData.student.lastName);
      setValue("student.ssn", data.formData.student.ssn!);

      setValue("student.birthDate", data.formData.student.birthDate!);
      setValue("student.email", data.formData.student.email);
      setValue("student.phone", data.formData.student.phone);
      data.formData.student.workPhone ? setValue("student.workPhone", data.formData.student.workPhone!) : setValue("student.workPhone", '');

      setValue("student.addressStreet", data.formData.student.addressStreet!);
      setValue("student.addressCity", data.formData.student.addressCity!);
      setValue("student.addressState", data.formData.student.addressStateFull!);
      setValue("student.addressZip", data.formData.student.addressZip!);
      setValue("student.addressCountry", data.formData.student.addressCountry ? data.formData.student.addressCountry : 'USA');
      setStudent(data.formData.student);
   }, [])

   useEffect(() => {
      if (visible) {
         setTimeout(() => {
            window.scrollTo(0, 0);
            validateForm();
         }, 500);
         loadStates();
      }
   }, [visible]);

   if (!visible) return null;

   return (
      <div className="exitCounseling-page1">
         <div className="row">
            <div className="col-12">
               <h3 className="title">Student Contact Information and Acknowledgment</h3>
               <p>
                  You are required to provide your current contact information to your loan servicer. If you complete exit counseling online, your contact informartion will automatically be provided to your loan servicer. If you did not (and will not) complete exit counseling online, you may use this page to provide the information to your school. You are also required to notify your loan servicer of any changes to your contact information after you leave school. You can use this page for that as well. You must complete all items on both the front and back.
               </p>
               <p><b>Important!</b> You must provide your loan servicer with you contact information as well as information on your closest living relative and two personal references. You must keep the information current.</p>
            </div>
         </div>
         <div className="row">
            <div className="col-12">
               <h3 className="subtitle">Personal Information</h3>
            </div>
         </div>
         <div className="row">
            <div className="col-4">
               <TextField
                  disabled
                  label="Last Name"
                  field="student.lastName"
                  type="text"
                  register={register}
                  errors={errors}
               />
            </div>
            <div className="col-4">
               <TextField
                  disabled
                  label="First Name"
                  field="student.firstName"
                  type="text"
                  register={register}
                  errors={errors}
               />
            </div>
            <div className="col-4">
               <TextField
                  disabled
                  label="Middle Name (Optional)"
                  field="student.middleName"
                  type="text"
                  register={register}
                  errors={errors}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-12">
               <TextField
                  disabled
                  label="Permanent Home Street Address"
                  field="student.addressStreet"
                  type="text"
                  register={register}
                  errors={errors}
               />
            </div>
            <div className="row">
               <div className="col-3">
                  <TextField
                     disabled
                     label="City"
                     field="student.addressCity"
                     type="text"
                     register={register}
                     errors={errors}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     disabled
                     errors={errors}
                     label="State"
                     field='student.addressState'
                     type='text'
                     register={register}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     disabled
                     label="Zip/Postal Code"
                     field="student.addressZip"
                     type="text"
                     register={register}
                     errors={errors}
                     mask={MASKS.zipCodeMask}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     disabled
                     label="Country"
                     field="student.addressCountry"
                     type="text"
                     register={register}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-6">
                  <TextField
                     disabled
                     label="Area Code/Telephone Number"
                     field="student.phone"
                     type="text"
                     register={register}
                     errors={errors}
                  />
               </div>
               <div className="col-6">
                  <TextField
                     disabled
                     label="Email Address"
                     field="student.email"
                     type="email"
                     register={register}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-6">
                  <TextField
                     label="Driver's License or State ID Number (Optional)"
                     field="extraData.driverLicense"
                     type="text"
                     register={register}
                     errors={errors}
                  />
               </div>
               <div className="col-6">
                  <SelectField
                     label="Issuing State"
                     field="extraData.issuingState"
                     errors={errors}
                     control={control}
                     labelKey="name"
                     valueKey="id"
                     rules={{ required: isDriverLicenseRequired }}
                     options={states.map(s => ({ id: s.name, name: s.name }))}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <TextField
                     label="Expected Employer (Leave blank if unknown)"
                     field="extraData.expectedEmployer"
                     type="text"
                     register={register}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <TextField
                     label="Employer's Street Address"
                     field="extraData.employerStreet"
                     type="text"
                     rules={{ required: isExpectedEmployer }}
                     register={register}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-3">
                  <TextField
                     label="Employer's City"
                     field="extraData.employerCity"
                     type="text"
                     rules={{ required: isExpectedEmployer }}
                     register={register}
                     errors={errors}
                  />
               </div>
               <div className="col-3">
                  <SelectField
                     label="Employer's State"
                     field="extraData.employerState"
                     errors={errors}
                     control={control}
                     labelKey="name"
                     rules={{ required: isExpectedEmployer }}
                     valueKey="id"
                     options={states.map(s => ({ id: s.name, name: s.name }))}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     label="Employer's Zip/Postal Code"
                     field="extraData.employerZip"
                     type="text"
                     rules={{ required: isExpectedEmployer }}
                     register={register}
                     errors={errors}
                     mask={MASKS.zipCodeMask}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     label="Employer's Country"
                     field="extraData.employerCountry"
                     type="text"
                     rules={{ required: isExpectedEmployer }}
                     register={register}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <PhoneField
                     label="Employer's Area Code/Telephone Number"
                     field="extraData.employerPhone"
                     parentClass="form-group"
                     type="text"
                     placeholder="(xxx) xxx-xxxx"
                     register={register}
                     errors={errors}
                     control={control}
                     rules={{ minLength: 10, required: isExpectedEmployer }}
                  />
               </div>
            </div>
            <div className="row">
               <h3 className="subtitle">
                  Enter closest living relative with a U.S. address different from yours, who will know your whereabouts for at least three years.
               </h3>
            </div>
            <div className="row">
               <div className="col-4">
                  <TextField
                     label="Last Name"
                     field="extraData.relativeLastName"
                     type="text"
                     register={register}
                     errors={errors}
                     rules={{ required: true }}
                  />
               </div>
               <div className="col-4">
                  <TextField
                     label="First Name"
                     field="extraData.relativeFirstName"
                     type="text"
                     register={register}
                     errors={errors}
                     rules={{ required: true }}
                  />
               </div>
               <div className="col-4">
                  <TextField
                     label="Middle Name(Optional)"
                     field="extraData.relativeMiddleName"
                     type="text"
                     register={register}
                     errors={errors}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <TextField
                     label="Permanent Home Street Address"
                     field="extraData.relativeStreet"
                     type="text"
                     register={register}
                     errors={errors}
                     rules={{ required: true }}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-3">
                  <TextField
                     label="City"
                     field="extraData.relativeCity"
                     type="text"
                     register={register}
                     errors={errors}
                     rules={{ required: true }}
                  />
               </div>
               <div className="col-3">
                  <SelectField
                     label="State"
                     field="extraData.relativeState"
                     errors={errors}
                     control={control}
                     rules={{ required: true }}
                     labelKey="name"
                     valueKey="id"
                     options={states.map(s => ({ id: s.name, name: s.name }))}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     label="Zip/Postal Code"
                     field="extraData.relativeZip"
                     type="text"
                     register={register}
                     errors={errors}
                     mask={MASKS.zipCodeMask}
                     rules={{ required: true }}
                  />
               </div>
               <div className="col-3">
                  <TextField
                     label="Country"
                     field="extraData.relativeCountry"
                     type="text"
                     register={register}
                     errors={errors}
                     rules={{ required: true }}
                  />
               </div>
            </div>
            <div className="row">
               <div className="col-6">
                  <PhoneField
                     label="Phone Number"
                     field="extraData.relativePhone"
                     parentClass="form-group"
                     type="text"
                     placeholder="(xxx) xxx-xxxx"
                     register={register}
                     errors={errors}
                     control={control}
                     rules={{ required: true, minLength: 10 }}
                  />
               </div>
               <div className="col-6">
                  <TextField
                     label="Email"
                     field="extraData.relativeEmail"
                     type="email"
                     register={register}
                     errors={errors}
                     rules={{ required: true }}
                  />
               </div>
            </div>
            <div className="position-relative">
               {getInitials("pag1" )}
            </div>
         </div>
      </div>
   );
});