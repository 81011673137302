import commonService from "../../../services/common.service";
import globalContext from "../../../context/globalContext";
import Modal from "../../../components/modal/modal";
import RadioButton from "../../../components/shared/Input/radioButton";
import applicationService from "../../../services/applicationRequest.service";
import TextField from "../../../components/shared/Input/textField";
import SelectField from "../../../components/shared/Input/selectField";
import { ApplicationCitizenshipInfo, ApplicationForm, ApplicationFormMilitaryInfo } from "../../../models/ApplicationForm.model";
import { ApplicationStatus, CountryId, SchoolID, StudentStatus } from "../../../models/Enums";
import { CheckboxList } from "../../../components/shared/Input/checkBox";
import { State } from "../../../models/common/State.model";
import { useContext, useEffect, useState } from "react";
import { ChevronRight as IArrow } from "react-feather";
import { useForm } from "react-hook-form";
import { AlertType } from "../../../models/AlertType.enum";
import studentService from "../../../services/student.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { UserMessages } from "../../../components/shared/constants/constants";

import { preferredPronounOptions, maritalStatusOptions, raceOptions, militaryBranchOptions, vaChapters } from "../commonOptions";

import { checkList, hearAboutUsOptions } from "./sanDiegoGlobalOptions";
import { Program, ProgramStartDate } from "../../../models/Program.model";
import programsService from "../../../services/programs.service";
import { Country } from "../../../models/common/Country.model";
import { PATTERNS, MASKS } from "../../../components/shared/Input/patterns";
import { ProgressBar } from "react-bootstrap";
import dateService from "../../../services/date.service";

import "./sanDiegoGlobal.scss";
import PhoneField from "../../../components/shared/Input/phoneField/phoneField";

const SanDiegoGlobal = (props: any) => {
	const { clearAlert, setLoading, showAlert } = useContext(globalContext);
	var moment = require("moment");

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		watch,
	} = useForm<ApplicationForm>();

	const [states, setStates] = useState<State[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [programs, setPrograms] = useState<Program[]>([]);
	const [startDates, setStartDates] = useState([] as any);
	const [modalVisible, setModalVisible] = useState(false);
	const [form, setForm] = useState(new ApplicationForm());
	const [formId, setFormId] = useState(0);
	const [editing, setEditing] = useState(false);
	const [editingToken, setEditingToken] = useState("");
	const [queryString, setQueryString] = useState("");
	const [otherPronounVisible, setOtherPronounVisible] = useState(false);
	const [agreeTerms, setAgreeTerms] = useState(false);
	const [isButtonClicked, setIsButtonClicked] = useState(false);
	const [duplicatedEmail, setDuplicatedEmail] = useState(false);

	const countryWatch = watch("student.addressCountry");
	const raceWatch = watch("race");
	const birthCountryWatch = watch("citizenship.birthCountry");
	const citizenWatch = watch("citizenship.citizen");
	const englishSpeakerWatch = watch("citizenship.englishSpeaker");
	const alienNumberWatch = watch("citizenship.alienNumber");
	const englishExamWatch = watch("citizenship.englishExam");
	const militaryStatusWatch = watch("militaryInfo.militaryRecord");
	const payOtherWatch = watch("payOther");
	const payMilitaryWatch = watch("payMilitaryBenefits");
	const programWatch = watch("program");

	const loadInitialData = async () => {
		let statesReq = commonService.getStates();
		let countriesReq = commonService.getCountries();
		let programsReq = programsService.getProgramsBySchool(SchoolID.SDGKU);
		let startDateReq = programsService.getStartDatesBySchool(SchoolID.SDGKU);

		setLoading(true, "Loading your data...");
		let [statesRes, programsRes, countriesRes, startDateRes] = await Promise.all([statesReq, programsReq, countriesReq, startDateReq]);
		setLoading(false);
		setStates(statesRes);
		setCountries(countriesRes);
		setPrograms(programsRes?.data ?? []);

		setStartDates(startDateRes.map((record: any) => ({ ...record, date: dateService.formatYMD(record.startDate) })) ?? []);

		if (props.match?.params?.id) {
			const params = new URLSearchParams(props.location.search);
			const token = params.get("token");
			if (token) {
				setEditingToken(token);
				loadExistingApplication(token);
			}
		}
	};

	const getStartDates = () => {
		const programId = programs.find((p) => p.name === programWatch)?.id;
		if (startDates.length > 0 && programId) {
			const pivot = moment().add(-1, "months");
			return startDates
				.filter((x: ProgramStartDate) => x.programId === programId && moment(x.startDate).isAfter(pivot))
				.slice(0, 3)
				.map((x: ProgramStartDate) => {
					return { value: x.startDate, label: x.startDate };
				});
		}
		return [];
	};

	const handleModalClose = () => {
		setModalVisible(false);
		props.history.push(`/public/sdgku/thank_you/?step=${editing ? "2" : "1"}`);
	};

	const handlePronounSelectionChanged = (opt: any) => {
		setOtherPronounVisible(opt.target.value === "Other");
	};

	const calculateAge = (e: any) => {
		let dob = e.target.value;
		if (dob) {
			let date = new Date(dob);
			let ageDifMs = Date.now() - date.getTime();
			let ageDate = new Date(ageDifMs);
			setValue("student.age", Math.abs(ageDate.getUTCFullYear() - 1970));
		}
	};

	const formatYesNoBoolean = (value: any) => {
		// if value is boolean
		if (typeof value === "boolean") {
			return value ? "1" : "0";
		}
		return value;
	};

	const loadExistingApplication = async (token: string) => {
		try {
			// Load the form data
			let editingId = props.match.params.id;
			setFormId(editingId);

			if (editingId) {
				setLoading(true, "Loading your data...");
				let form = await applicationService.getApplicationRequest(editingId, token);
				if (form) {
					let adjustedForm = {
						...form,
						guardianAgree: formatYesNoBoolean(form.guardianAgree),

						citizenship: {
							...form.citizenship,
							citizen: formatYesNoBoolean(form.citizenship?.citizen),
							visa: formatYesNoBoolean(form.citizenship?.visa),
							alienNumber: formatYesNoBoolean(form.citizenship?.alienNumber),
							englishSpeaker: formatYesNoBoolean(form.citizenship?.englishSpeaker),
							englishExam: formatYesNoBoolean(form.citizenship?.englishExam),
						} as ApplicationCitizenshipInfo,

						militaryInfo: {
							...form.militaryInfo,
							benefits: formatYesNoBoolean(form.militaryInfo?.benefits),
							honorableDischarged: formatYesNoBoolean(form.militaryInfo?.honorableDischarged),
							chapter: formatYesNoBoolean(form.militaryInfo?.chapter),
						} as ApplicationFormMilitaryInfo,
					};

					if (adjustedForm.academicInfo) {
						adjustedForm.academicInfo.collegeGraduated = adjustedForm.academicInfo.collegeGraduated ? 1 : 0;
						adjustedForm.academicInfo.highGraduated = adjustedForm.academicInfo.highGraduated ? 1 : 0;
						adjustedForm.academicInfo.trainingGraduated = adjustedForm.academicInfo.collegeGraduated ? 1 : 0;
					}

					setEditing(true);
					setForm(adjustedForm);
					setValue("student", adjustedForm.student);
					setValue("citizenship", adjustedForm.citizenship);
					setValue("militaryInfo", adjustedForm.militaryInfo);
					setValue("program", adjustedForm.program);
					setValue("hearAboutUs", adjustedForm.hearAboutUs);
					setValue("startDate", adjustedForm.startDate);
					setValue("occurrence", adjustedForm.occurrence);
					setValue("courseLength", adjustedForm.courseLength);
					setValue("payCreditCard", adjustedForm.payCreditCard);
					setValue("payCheck", adjustedForm.payCheck);
					setValue("payFinancialAid", adjustedForm.payFinancialAid);
					setValue("payMilitaryBenefits", adjustedForm.payMilitaryBenefits);
					setValue("payScholarship", adjustedForm.payScholarship);
					setValue("payOther", adjustedForm.payOther);
					setValue("payOtherName", adjustedForm.payOtherName);
					setValue("race", adjustedForm.race);
					setValue("ethnicity", adjustedForm.ethnicity);
					setValue("citizenship.birthCountry", adjustedForm.citizenship.birthCountry);
					setValue("citizenship.birthState", adjustedForm.citizenship.birthState);

					// set the preferred pronoun
					if (!preferredPronounOptions.includes(adjustedForm.student?.preferredPronoun)) {
						setValue("student.preferredPronounOther", adjustedForm.student?.preferredPronoun);
						setValue("student.preferredPronoun", "Other");
						setOtherPronounVisible(true);
					}
				}
			}
		} catch (e) {
			console.log("error loading data", e);
		} finally {
			setLoading(false);
		}
	};

	const handleModalAccept = async () => {
		setModalVisible(false);
		props.history.push(`/public/sdgku/2/${formId}?${queryString}`);
	};

	const handleSave = async (data: any) => {
		clearAlert();

		setIsButtonClicked(true);

		let payload = { ...data } as ApplicationForm;
		if (payload.startDate) {
			payload.student.startDate = moment(payload.startDate).format("YYYY-MM-DD");
		}
		payload.student.school = SchoolID.SDGKU;
		payload.student.status = StudentStatus.APPLICATION_REQUEST;
		payload.status = ApplicationStatus.SUBMITTED;
		if (payload.student.preferredPronoun === "Other") {
			payload.student.preferredPronoun = (payload.student as any)?.preferredPronounOther;
		}

		// delete the other as is not expected on backend, it is just temporal to FE
		delete (payload.student as any)?.preferredPronounOther;

		// get the program id
		let programId = programs.find((p) => p.name === payload.program)?.id;
		if (programId) {
			payload.program_id = programId;
		}

		setLoading(true, "Saving data, please wait");

		try {
			if (editing) {
				payload.id = formId;
				let resp = await applicationService.updateApplicationRequest(payload, editingToken);
				if (resp?.status === 200) {
					setQueryString(`first=${payload.student.firstName}&last=${payload.student.lastName}&token=${editingToken}`);
					reset(); // reset form
					setModalVisible(true);
				} else {
					showAlert("Error updating application", AlertType.Error);
				}
			} else {
				let response = await applicationService.saveApplicationRequest(payload);
				if (response) {
					setFormId(response.id);
					setQueryString(`first=${payload.student.firstName}&last=${payload.student.lastName}`);
					reset(); // reset form
					setModalVisible(true);
				}
			}
		} catch (e) {
			console.log("error saving data", e);
		} finally {
			setLoading(false);
			setIsButtonClicked(false);
		}
	};

	const getAlertBody = () => {
		if (editing) {
			return (
				<>
					Thank you for updating your data.
					<br />
					<br />
					Click on the button below if you wish to continue updating data on second step.
				</>
			);
		}

		return (
			<>
				Thank you for filling the initial application for admission.
				<br />
				<br />
				We sent you a confirmation email with a link to complete the application.
				<br />
				If you do not receive the email, please check your spam folder.
				<br />
			</>
		);
	};

	const handleEmailChanged = async (e: any) => {
		let response = await studentService.validateEmail(e.target.value);
		if (response?.status !== HttpStatusCode.Ok) {
			setDuplicatedEmail(true);
			showAlert(UserMessages.errors.student_duplicated_email, AlertType.Error);
		} else {
			setDuplicatedEmail(false);
			clearAlert();
		}
	};

	const handleProgramDuration = (option: any) => {
		if (option?.value) {
			setForm((prevState) => ({ ...prevState, courseLength: option.duration }));
		} else {
			setForm((prevState) => ({ ...prevState, courseLength: "" }));
		}
	};

	const handleAgreeTerms = () => {
		setAgreeTerms(!agreeTerms);
	};

	useEffect(() => {
		setForm({ ...form, academicInfo: {} as any });
		loadInitialData();
	}, []);

	const checkKeyDown = (e: any) => {
		if (e.code === "Enter") e.preventDefault();
	};

	const durationName = (p: Program) => {
		if (p.unit === "months") {
			if (p.length === 50) {
				return "Approx 4 year 2 months";
			} else if (p.length === 18) {
				return "Approx 1 year 6 months";
			}
		}

		return `Approx ${p["length"]} ${p["unit"]}`;
	};

	const getProgramsToDisplay = () => {
		return programs.map((p) => ({ id: p.id, value: p.name, label: p.name, duration: durationName(p) }));
	};

	const isUSA = () => countryWatch === (CountryId.USA as any);
	const declineRace = () => raceWatch === "Decline Answer";
	const USABirth = () => birthCountryWatch === (CountryId.USA as any);
	const isCitizen = () => citizenWatch === "1" || citizenWatch === null;
	const nonNativeEnglish = () => englishSpeakerWatch === "1";
	const hasAlienNum = () => alienNumberWatch === "1";
	const hasEnglishExam = () => englishExamWatch === "1";
	const notMilitary = () => militaryStatusWatch === "None";
	const isMilitaryDependant = () => militaryStatusWatch?.includes("Dependant");
	const isVeteran = () => militaryStatusWatch === "U.S. Veteran";

	return (

		<div className="sdgku-form">
			<form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="preapp-form container sd-form">
				<Modal
					visible={modalVisible}
					cancelButtonText="Done"
					cancelButtonClass="btn-dark"
					mainButtonText={
						editing ? (
							<>
								Continue to step 2 <IArrow />
							</>
						) : null
					}
					title="Initial Admission Application"
					body={getAlertBody()}
					onCancel={handleModalClose}
					onClose={handleModalClose}
					onAccept={handleModalAccept}
				></Modal>

				<h2>
					<img src="/static/sdgku/logo.png" alt="PA logo"></img>
					<span className="page-title">Application for Admission</span>
				</h2>

				{editing && (
					<div className="custom-progress-wrapper col-sm-10 offset-sm-1 col-md-4 offset-md-4">
						<div className="step-button bg-primary progress-bar progress-bar completed" id="step-1">
							<p>Step 1</p>
						</div>
						<a
							href={`${window.location.origin}/public/SDGKU/2/${form.id}/?first=${form.student.firstName}&last=${form.student.lastName}&token=${editingToken}`}
							className="step-button"
							id="step-2"
						>
							<p>Step 2</p>
						</a>
						<div className="step-button" id="step-3">
							<p>Done</p>
						</div>
						<ProgressBar variant="primary" now={25} />
					</div>
				)}

				<div className="two-columns mg-t-20">
					<div>
						<SelectField
							field="program"
							label="Name of the Program"
							errors={errors}
							control={control}
							rules={{ required: true }}
							onChange={handleProgramDuration}
							options={getProgramsToDisplay()}
						/>

						<SelectField
							field="hearAboutUs"
							label="How did you hear about us?"
							errors={errors}
							control={control}
							rules={{ required: true }}
							options={hearAboutUsOptions}
						/>
					</div>

					<div className="form-group application-type">
						<label>Type of applicant:</label>
						<RadioButton
							field="occurrence"
							label="First Time Applicant"
							value="first"
							register={register}
							errors={errors}
							rules={{ required: true }}
						></RadioButton>
						<RadioButton
							field="occurrence"
							label="Returning Applicant"
							value="returning"
							register={register}
							errors={errors}
							rules={{ required: true }}
						></RadioButton>
					</div>
					<div>
						<div className="two-columns">
							<div>
								<SelectField
									parentClass="form-group"
									field="startDate"
									label="Program or Course Start Date"
									control={control}
									options={getStartDates()}
									errors={errors}
									rules={{ required: true }}
								/>
							</div>

							<div>
								<TextField
									parentClass="form-group"
									field="courseLength"
									maxLength="30"
									type="text"
									label="Program or Course length"
									placeholder="Enter the length"
									register={register}
									value={form?.courseLength || ""}
									errors={errors}
								/>
							</div>
						</div>
					</div>
				</div>

				<h3>Personal Information</h3>

				<div className="two-columns">
					<SelectField
						field="student.gender"
						label="Gender"
						errors={errors}
						control={control}
						rules={{ required: true }}
						options={[
							{ label: "Male", value: 1 },
							{ label: "Female", value: 2 },
						]}
					/>

					<div className="two-columns">
						<SelectField
							field="student.preferredPronoun"
							label="Preferred Pronoun"
							errors={errors}
							rules={{ required: true }}
							control={control}
							options={preferredPronounOptions.map((option) => ({ label: option, value: option }))}
						/>

						<TextField
							visible={otherPronounVisible}
							parentClass="form-group"
							field="student.preferredPronounOther"
							maxLength="50"
							type="text"
							label="Other"
							placeholder="Enter your preferred name"
							register={register}
						/>
					</div>

					<TextField
						parentClass="form-group"
						field="student.birthDate"
						type="date"
						label="Date of Birth"
						onChange={calculateAge}
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>

					<TextField
						parentClass="form-group"
						field="student.age"
						type="number"
						label="Age"
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>
				</div>

				<div className="two-columns">
					<TextField
						parentClass="form-group"
						field="student.firstName"
						maxLength="70"
						type="text"
						label="First name"
						placeholder="Enter your first name"
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>

					<TextField
						parentClass="form-group"
						field="student.middleName"
						maxLength="70"
						type="text"
						label="Middle name"
						placeholder="Enter your middle name"
						register={register}
					/>

					<TextField
						parentClass="form-group"
						field="student.lastName"
						maxLength="70"
						type="text"
						label="Last name(s)"
						placeholder="Enter your last name"
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>

					<SelectField
						field="student.maritalStatus"
						label="Marital Status"
						errors={errors}
						control={control}
						options={maritalStatusOptions}
					/>

					<TextField
						parentClass="form-group"
						field="student.otherName"
						maxLength="100"
						type="text"
						includeColon={false}
						label={
							<>
								Previous Name:{" "}
								<span>Other name under which your transcripts might appear, if different from above, (First, Middle, Last)</span>
							</>
						}
						placeholder="Enter other name"
						register={register}
					/>

					<TextField
						parentClass="form-group"
						field="student.legalGuardian"
						maxLength="100"
						type="text"
						includeColon={false}
						label={
							<>
								Name of Legal Parent/Guardian, or Spouse <span>(Please select if applicable)</span>
							</>
						}
						placeholder="Enter other name"
						register={register}
					/>
				</div>

				<div className="two-columns">
					<SelectField
						field="student.addressCountry"
						label="Country"
						errors={errors}
						control={control}
						rules={{ required: true }}
						labelKey="name"
						valueKey="id"
						options={countries}
					/>
					<SelectField
						field="student.addressState"
						label="State"
						errors={errors}
						control={control}
						rules={{ required: true }}
						visible={isUSA()}
						labelKey="name"
						valueKey="id"
						options={states}
					/>

					<TextField
						field="student.addressStateName"
						maxLength="100"
						label="State"
						placeholder="Enter your state"
						type="text"
						errors={errors}
						register={register}
						visible={!isUSA()}
					/>

					<TextField
						parentClass="form-group"
						field="student.addressCity"
						maxLength="100"
						type="text"
						label="City"
						placeholder="Enter your city"
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>

					<TextField
						parentClass="form-group"
						field="student.addressStreet"
						maxLength="100"
						type="text"
						label="Address"
						placeholder="Enter your street"
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>

					<TextField
						parentClass="form-group"
						mask={isUSA() ? MASKS.zipCodeMask : ""}
						field="student.addressZip"
						maxLength="10"
						type="text"
						label="Zip Code"
						placeholder={MASKS.zipCodeMask}
						register={register}
						errors={errors}
						rules={{ required: true, pattern: isUSA() ? PATTERNS.zipCodeRegEx : null }}
					/>

					<div></div>

					<PhoneField
						parentClass="form-group"
						field="student.phone"
						label="Telephone"
						placeholder="Enter your telephone"
						control={control}
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>

					<PhoneField
						parentClass="form-group"
						field="student.workPhone"
						label="Alternative Phone"
						control={control}
						placeholder="Enter your alternative phone"
						register={register}
						errors={errors}
					/>

					<TextField
						parentClass="form-group"
						field="student.email"
						maxLength="70"
						type="text"
						label="Email"
						placeholder="Enter your email"
						register={register}
						errors={errors}
						onBlur={handleEmailChanged}
						rules={{
							required: true,
							pattern: PATTERNS.emailRegEx,
						}}
					/>

					<TextField
						parentClass="form-group"
						field="student.ssn"
						type="text"
						label="Social Security Number"
						mask={MASKS.ssnMask}
						register={register}
						errors={errors}
						rules={
							isUSA()
								? {
									required: true,
									pattern: PATTERNS.ssnRegEx,
								}
								: {}
						}
					/>

					<TextField
						parentClass="form-group grid-child-full mg-t-10"
						field="student.permanentAddress"
						type="text"
						label="Permanent Address, if different from mailing address above (Street Number and Name, City, State, Zip Code, Country)"
						placeholder="Enter your permanent address"
						register={register}
						errors={errors}
						rules={{
							required: false,
						}}
					/>
				</div>

				<h6 className="mg-t-30 mg-b-20">
					Race/Ethnicity information is optional. Any information you provide will not be used for discriminatory purposes.
				</h6>
				<div className="two-columns">
					<SelectField
						field="race"
						label="What's your ethnicity or racial background?"
						includeColon={false}
						errors={errors}
						control={control}
						rules={{ required: true }}
						options={raceOptions}
					/>

					<SelectField
						field="ethnicity"
						label="Are you Hispanic or Latino?"
						includeColon={false}
						errors={errors}
						control={control}
						rules={{ required: true }}
						options={[
							{ value: "Hispanic Latino", label: "Yes" },
							{ value: "Not Hispanic", label: "No" },
						]}
					/>
				</div>

				<h3>Current Citizenship</h3>
				<h5>Place of Birth</h5>
				<div className="three-columns">
					<SelectField
						parentClass="form-group"
						field="citizenship.birthCountry"
						label="Country"
						errors={errors}
						control={control}
						rules={{ required: true }}
						labelKey="name"
						valueKey="id"
						options={countries}
					/>

					<SelectField
						parentClass="form-group"
						field="citizenship.birthState"
						label="State"
						visible={USABirth()}
						errors={errors}
						control={control}
						rules={{ required: true }}
						labelKey="name"
						valueKey="id"
						options={states}
					/>

					<TextField
						parentClass="form-group"
						field="citizenship.birthCity"
						maxLength="100"
						type="text"
						visible={USABirth()}
						label="City"
						placeholder="Enter your city"
						register={register}
						errors={errors}
						rules={{ required: true }}
					/>
				</div>

				<div className="three-columns mg-t-10">
					<div className="form-group">
						<label>Are you citizen of the United States?</label>
						<RadioButton
							field="citizenship.citizen"
							label="Yes"
							value="1"
							register={register}
							errors={errors}
							rules={{ required: true }}
						></RadioButton>
						<RadioButton
							field="citizenship.citizen"
							label="No"
							value="0"
							register={register}
							errors={errors}
							rules={{ required: true }}
						></RadioButton>
					</div>

					{!isCitizen() ? (
						<div>
							<label>Are you permanent U.S. resident visa?</label>
							<RadioButton
								field="citizenship.visa"
								label="Yes"
								value="1"
								register={register}
								errors={errors}
								rules={{ required: true }}
							></RadioButton>
							<RadioButton
								field="citizenship.visa"
								label="No"
								value="0"
								register={register}
								errors={errors}
								rules={{ required: true }}
							></RadioButton>
						</div>
					) : null}

					{!isCitizen() ? (
						<div>
							<label>Do you have any Alien registration number?</label>
							<div className="d-flex">
								<div>
									<RadioButton
										field="citizenship.alienNumber"
										label="Yes"
										value="1"
										register={register}
										errors={errors}
										rules={{ required: true }}
									></RadioButton>
									<RadioButton
										field="citizenship.alienNumber"
										label="No"
										value="0"
										register={register}
										errors={errors}
										rules={{ required: true }}
									></RadioButton>
								</div>
								<TextField
									field="citizenship.alienRegistrationNumber"
									maxLength="12"
									type="text"
									visible={hasAlienNum()}
									includeColon={false}
									placeholder="Alien Registration Number"
									register={register}
									errors={errors}
									rules={{ required: hasAlienNum() }}
								/>
							</div>
						</div>
					) : null}
				</div>

				<div className="three-columns">
					<div>
						<label>Are you a non-native English Speaker?</label>
						<RadioButton
							field="citizenship.englishSpeaker"
							label="Yes"
							value="1"
							register={register}
							errors={errors}
							rules={{ required: true }}
						></RadioButton>
						<RadioButton
							field="citizenship.englishSpeaker"
							label="No"
							value="0"
							register={register}
							errors={errors}
							rules={{ required: true }}
						></RadioButton>
					</div>

					{nonNativeEnglish() ? (
						<div>
							<label>Have you taken an English Language proficiency TOEFL or ELPW exam?</label>
							<div className="d-flex">
								<div>
									<RadioButton
										field="citizenship.englishExam"
										label="Yes"
										value="1"
										register={register}
										errors={errors}
										rules={{ required: true }}
									></RadioButton>
									<RadioButton
										field="citizenship.englishExam"
										label="No"
										value="0"
										register={register}
										errors={errors}
										rules={{ required: true }}
									></RadioButton>
								</div>
								<TextField
									field="citizenship.examScore"
									maxLength="12"
									type="text"
									visible={hasEnglishExam()}
									includeColon={false}
									placeholder="Exam Score"
									register={register}
									errors={errors}
									rules={{ required: hasEnglishExam() }}
								/>
							</div>
						</div>
					) : null}
				</div>

				<h3>U.S. Military</h3>
				<div className="two-columns">
					<div className="form-group">
						<label>Are you</label>
						<RadioButton
							field="militaryInfo.militaryRecord"
							label="None"
							value="None"
							register={register}
							errors={errors}
							rules={{ required: true }}
						/>
						<RadioButton
							field="militaryInfo.militaryRecord"
							label="Active U.S. Military"
							value="Active U.S. Military"
							register={register}
							errors={errors}
							rules={{ required: true }}
						/>
						<RadioButton
							field="militaryInfo.militaryRecord"
							label="U.S. National Guard or Reserve"
							value="U.S. National Guard or Active"
							register={register}
							errors={errors}
							rules={{ required: true }}
						/>
						<RadioButton
							field="militaryInfo.militaryRecord"
							label="U.S. Veteran"
							value="U.S. Veteran"
							register={register}
							errors={errors}
							rules={{ required: true }}
						/>
						<RadioButton
							field="militaryInfo.militaryRecord"
							label="Dependant of U.S. Veteran"
							value="Dependant of U.S. Veteran"
							register={register}
							errors={errors}
							rules={{ required: true }}
						/>
						<RadioButton
							field="militaryInfo.militaryRecord"
							label="Dependant of Active U.S. Military"
							value="Dependant of Active U.S. Military"
							register={register}
							errors={errors}
							rules={{ required: true }}
						/>
					</div>

					<div>
						<SelectField
							field="militaryInfo.militaryBranch"
							label="If you are/were a part of the U.S. Military, which branch"
							errors={errors}
							control={control}
							visible={!notMilitary()}
							rules={{ required: true }}
							options={militaryBranchOptions}
						/>

						<div className="two-columns">
							<TextField
								field="militaryInfo.dateFrom"
								maxLength="50"
								parentClass="form-group"
								visible={!notMilitary() && !isMilitaryDependant()}
								type="text"
								label="Active from (mm/yyyy)"
								register={register}
							/>
							<TextField
								field="militaryInfo.dateTo"
								maxLength="50"
								parentClass="form-group"
								visible={isVeteran()}
								type="text"
								label="Active to (mm/yyyy)"
								register={register}
							/>
						</div>
					</div>
				</div>

				{!notMilitary() ? (
					<div className="two-columns">
						<div>
							<label>Are you planning to use Veteran Education Benefits to pay for your course or program at SDGKU?</label>
							<RadioButton
								field="militaryInfo.benefits"
								label="Yes"
								value="1"
								register={register}
								errors={errors}
								rules={{ required: true }}
							></RadioButton>
							<RadioButton
								field="militaryInfo.benefits"
								label="No"
								value="0"
								register={register}
								errors={errors}
								rules={{ required: true }}
							></RadioButton>
						</div>

						{isVeteran() ? (
							<div>
								<label>Were you honorably discharged from a branch of the U.S. Military?</label>
								<RadioButton
									field="militaryInfo.honorableDischarged"
									label="Yes"
									value="1"
									register={register}
									errors={errors}
									rules={{ required: true }}
								></RadioButton>
								<RadioButton
									field="militaryInfo.honorableDischarged"
									label="No"
									value="0"
									register={register}
									errors={errors}
									rules={{ required: true }}
								></RadioButton>
							</div>
						) : null}
					</div>
				) : null}

				<h3>
					<span className="required">*</span> Method of Payment <span>(Select all that apply)</span>
				</h3>

				<div className="one-two-thirds-columns">
					<div className="form-group">
						<label>How do you plan to finance your education?</label>
						<div className="checks-group-payment row">
							<CheckboxList items={checkList} getValues={getValues} register={register} errors={errors} rules={{ required: true }} />
							<TextField
								field="payOtherName"
								type="text"
								maxLength="30"
								placeholder="Specify Other"
								visible={payOtherWatch}
								includeColon={false}
								register={register}
								errors={errors}
							/>
						</div>
					</div>
					{payMilitaryWatch ? (
						<div>
							<label>What Benefit will you be using?</label>
							{vaChapters.map((opt) => (
								<RadioButton
									field="militaryInfo.chapter"
									label={opt.label}
									value={opt.value}
									register={register}
									errors={errors}
									rules={{ required: false }}
								/>
							))}
						</div>
					) : null}
				</div>

				<h6 className="label-certify mg-b-20 d-flex">
					<div className="wd-10p d-flex align-content-center justify-content-center">
						<input
							type="checkbox"
							onChange={() => {
								handleAgreeTerms();
							}}
						/>
					</div>
					I understand that by clicking “Submit” below, San Diego Global Knowledge University (SDGKU) may email, call and/or text me about
					educational services and for related purposes such as advising, enrollment, tuition settlement or telemarketing at the email and
					phone number provided, including a wireless number, using automated technology. I understand that I am not required to provide this
					consent to attend SDGKU. As an alternative to providing this consent, you may receive information and/or enroll in a SDGKU program
					by calling (619) 934-0797 or Toll Free (800) 215-0541.
				</h6>

				<div className="save-container">
					<button
						type="submit"
						className="btn btn-primary"
						disabled={!agreeTerms || isButtonClicked || duplicatedEmail}
						onClick={handleSubmit((d) => handleSave(d))}
					>
						{duplicatedEmail ? "Email already exists" : "Submit Initial Application"}
					</button>
				</div>
			</form>
		</div>	
	);
};

export default SanDiegoGlobal;
