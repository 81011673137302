import { AgreementProps } from '../../types';
import classNames from '../sdgku.enrollment.module.css';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import usePageSignature from '../../../../../hooks/pageSignature';
import { SDGKUFormsPage, SignedFormKey } from '../../../../../models/Forms.enum';
import '../../index.css';

const formatMoney = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const SpanishAgreementPage7 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
  const { saveForm, getInitials } = usePageSignature(data, false);

  useImperativeHandle(ref, () => ({
    async saveData() {
      return await saveForm(SDGKUFormsPage.Agreement7);
    },
  }));

  useEffect(() => {
    visible && window.scrollTo(0, 0);
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="doc-content py-4">
      <p>
        <b>Requisitos de graduación:</b>
        {data.formKey == SignedFormKey.ENROLLMENT_AGREEMENT_SPANISH_2 ? (
          <ul>
            <li>La elegibilidad para la graduación requiere un promedio acumulativo mínimo de calificaciones (CGPA) de al menos 2.0 (C) para el programa BSGM.</li>
            <li>Completar satisfactoriamente todos los requisitos del plan de estudios en línea. Este programa de grado BSGM en SDGKU consta de 42 cursos equivalentes a 3 horas de crédito semestrales cada uno, para un total de 126 créditos académicos.</li>
            <li>Se han aceptado 78 créditos académicos de transferencia, por lo que el estudiante completará un total de 16 cursos equivalentes a 48 horas de créditos semestrales.</li>
          </ul>
        ) : (
          <ul>
            <li>La elegibilidad para la graduación requiere un promedio acumulativo mínimo de calificaciones (CGPA) de al menos 2.0 (C) para el programa BSGM.</li>
            <li>Completar satisfactoriamente todos los requisitos del plan de estudios en línea. Este programa de grado BSGM en SDGKU consta de 42 cursos equivalentes a 3 horas de crédito semestrales cada uno, para un total de 126 créditos académicos.</li>
            <li>Se han aceptado __ créditos académicos de transferencia, por lo que el estudiante completará un total de __ cursos equivalentes a __ horas de créditos semestrales.</li>
          </ul>
        )}
      </p>
      <p>
        La participación (física o virtual) en las actividades de graduación está supeditada a la finalización de todos los requisitos de graduación. En casos excepcionales en los que no se hayan
        completado los requisitos mínimos, se deberán hacer arreglos por adelantado con el Director Académico (CAO).
      </p>

      <p>
        <b>CUOTAS Y CARGOS: </b> El estudiante es responsable de las siguientes cuotas y cargos (consulte el Catálogo General de SDGKU para conocer los costos específicos de cada concepto)
      </p>
      <div className="container-fluid">
        {program?.fees.map((fee, index) => (
          <div className="row mb-2 align-items-end" key={index}>
            <div className={`${fee.feeCondition ? 'col-5' : 'col-9'}`}>
              <b>{fee.feeName}</b>
            </div>
            {fee.feeCondition && <div className="col-4 align-self-center">{fee.feeCondition}</div>}
            {fee?.feeAmount && fee?.feeAmount != -1 && <div className={`${classNames.amount} col-3 text-right`}>{`${formatMoney(fee.feeAmount)} USD`}</div>}
          </div>
        ))}
        <div className="row mb-2">
          <div className="col-10">
            <p>
              Cada institución que reúna los requisitos recaudará una cuota de zero dólares ($0.00) por cada mil dólares ($1,000) de cargos institucionales, redondeada al millar de dólares más
              cercano, de cada estudiante en un programa educativo que sea residente de California o esté inscrito en un programa de residencia. Para los cargos institucionales de mil dólares ($1,000)
              o menos, la cuota es cero dólares ($0).
            </p>
          </div>
        </div>
      </div>
      <p>
        <b>Forma de Pago: </b> San Diego Global Knowledge University (SDGKU), también conocida en el documento como SDGKU acepta los siguientes métodos de pago, efectivo, cheque y/o tarjeta de
        crédito, y/o ayuda financiera federal.
      </p>
      <p>
        <b>Nota: </b> matricularse, el estudiante está obligado a pagar la cuota de inscripción, la cuota de matrícula y la cuota de los libros de texto/materiales en línea para el primer curso de un
        programa de grado. Para los programas sin titulación, la cuota de inscripción, la cuota total de matrícula del programa y los materiales en línea deben pagarse en el momento de la inscripción.
        La cuota de evaluación S.T.R.F. debe incluirse en cada pago realizado por el estudiante). Debido a que San Diego Global Knowledge University ofrece programas de educación a distancia donde la
        instrucción no se ofrece en tiempo real, en tal caso deberá transmitir la primera lección y cualquier material al estudiante dentro de los siete días después de que San Diego Global Knowledge
        University acepte al estudiante para su admisión.
      </p>

      <div className="position-relative">{getInitials('page7')}</div>
    </div>
  );
});
