export const EnumToSelectOptions = (enumList: any, getEnumValue: (x: any) => string) => {
	let options = [];
	for (let key in Object.keys(enumList)) {
		let enumKey = enumList[key];
		if (enumKey) {
			let enumVal = enumList[enumKey];
			options.push({ value: enumVal, label: getEnumValue(+enumVal) });
		}
	}
	return options;
};

// should match helpers.StudentStatus on BE
export enum StudentStatus {
	APPLICATION_REQUEST = 1,
	APPLICATION_ALLOWED = 2,
	ENROLLED = 3,
	APPROVED = 4,
	ACTIVE = 5,
	GRADUATED = 6,
	CANCELLED = 7,
	WITHDRAWN = 8,
	LEAVE_OF_ABSENCE = 9,
	DEACTIVATED = 10,
}

export const StudentStatusName = (status: StudentStatus): string => {
	switch (status) {
		case StudentStatus.APPLICATION_REQUEST:
			return "App Request";
		case StudentStatus.APPLICATION_ALLOWED:
			return "App Allowed";
		case StudentStatus.ENROLLED:
			return "Enrolled";
		case StudentStatus.APPROVED:
			return "App Approved";
		case StudentStatus.ACTIVE:
			return "Active";
		case StudentStatus.GRADUATED:
			return "Graduated";
		case StudentStatus.CANCELLED:
			return "Cancelled";
		case StudentStatus.WITHDRAWN:
			return "Withdrawn";
		case StudentStatus.LEAVE_OF_ABSENCE:
			return "Leave of Absence";
		case StudentStatus.DEACTIVATED:
			return "Deactivated";
		default:
			return "Not Specified";
	}
};

export enum SchoolID {
  ERROR = 0,
  SCCBB = 1,
  PA = 2,
  SDGKU = 3,
  UWLA = 4,
  COBA = 5,
  NIUC = 7,
  UWLA2 = 9,
}

// should match helpers.FinancialOptions on BE
export enum FinancialOptions {
	FINANCIAL_AID = 1,
	CASH = 2,
	MILITARY_BENEFITS = 3,
	OTHER = 4,
}

export enum ApplicationStatus {
	SUBMITTED = 0,
	APPROVED = 1,
	REJECTED = 2,
	CANCELLED = 3,
	DELETED = 4,
	EXPIRED = 5,
}

export const ApplicationStatusName = (status: ApplicationStatus): string => {
	switch (status) {
		case ApplicationStatus.SUBMITTED:
			return "In Review";
		case ApplicationStatus.APPROVED:
			return "App Approved";
		case ApplicationStatus.REJECTED:
			return "Rejected";
		case ApplicationStatus.CANCELLED:
			return "Cancelled";
		case ApplicationStatus.DELETED:
			return "Deleted";
		case ApplicationStatus.EXPIRED:
			return "Expired";
		default:
			return "Not Specified";
	}
};

// should match fixtures.permissions on BE
export enum UserPermission {
	VIEW_USERS = 1,
	EDIT_USERS = 2,
	DELETE_USERS = 3,
	VIEW_ROLES = 4,
	EDIT_ROLES = 5,
	DELETE_ROLES = 6,
	VIEW_STUDENTS = 7,
	EDIT_STUDENTS = 8,
	DELETE_STUDENTS = 9,
	VIEW_SCHOOLS = 10,
	EDIT_SCHOOLS = 11,
	DELETE_SCHOOLS = 12,
}

export enum UserDepartmentStatus {
	PENDING = 0,
	APPROVED = 1,
	REJECTED = 2,
}

// should match documents. documentUploadType on BE
export enum DocumentUploadType {
	SING_REQUEST = 1,
	UPLOAD_REQUEST = 2,
	MANUAL_UPLOAD = 3,
	OTHER = 9,
}

// should match documents. documentStatus on BE
export enum DocumentStatus {
	PENDING = 1, 	 // Document is being processed
	APPROVED = 2, 	 // Document is approved
	DEACTIVATED = 3, // Document is removed
	REJECTED = 4, 	 // Document is rejected
	VOIDED = 5,		 // Document is voided
}

// should match helpers. PersonalReferenceType on BE
export enum PersonalReferenceType {
	NORMAL = 0,
	EMERGENCY_CONTACT = 1,
}

export enum UploadRequestStatus {
	PENDING = 0,
	VIEWED = 1,
	IN_PROGRESS = 2,
	COMPLETED = 3,
	CANCELLED = 9,
}

export const UploadRequestStatusText = (status: UploadRequestStatus): string => {
	switch (status) {
		case UploadRequestStatus.PENDING:
			return 'Pending';
		case UploadRequestStatus.VIEWED:
			return 'Viewed';
		case UploadRequestStatus.IN_PROGRESS:
			return 'In Progress';
		case UploadRequestStatus.COMPLETED:
			return 'Completed';
		case UploadRequestStatus.CANCELLED:
			return 'Cancelled';
		default:
			return "Not Specified";
	}
}
		

export enum UploadRequestDocumentStatus {
	MISSING = 0,
	IN_REVIEW = 1,
	ACCEPTED = 2,
	REJECTED = 9,
}

export const UploadRequestDocumentStatusText = (status: UploadRequestDocumentStatus): string => {
	switch (status) {
		case UploadRequestDocumentStatus.MISSING:
			return 'Missing';
			
		case UploadRequestDocumentStatus.IN_REVIEW:
			return 'In Review';
			
		case UploadRequestDocumentStatus.ACCEPTED:
			return 'Uploaded';
			
		case UploadRequestDocumentStatus.REJECTED:
			return 'Rejected';
		
		default:
			return "Not Specified";
	}
}

export enum EmailType {
	ACCOUNT_CREATED = 1,
	ACCOUNT_RECOVERY = 2,
	APPLICATION_APPROVED = 10,
	APPLICATION_CONTINUE = 11,
	APPLICATION_RECEIVED = 12,
	APPLICATION_REJECTED = 19,
	DOCUMENT_UPLOAD_REQUEST = 20,
	DOCUMENT_UPLOADED_NOTIFICATION = 21,
	DOCUMENT_SEND = 22,
}

export enum CountryId {
	USA = 1,
	Mexico = 2,
	Canada = 40,
}

export enum COBAProgram {
	BARBER = 1,
	COSMETOLOGY = 2,
	ESTHETICIAN = 3,
	BARBER_HYBRID = 4,
}

export enum SDGKUProgram {
	MSCT_ESP = 14,
	MSCT_HYBRID = 13,
	MSCT = 7,
	MSIM_HYBRID = 12,
	MSIM = 6,
	BSGM_HYBRID = 11,
	BSGM_ESP = 10,
	BSGM = 5,
	FSDI_HYBRID = 9,
	FSDI = 3,
}

// see documentation > BSDM Fees for more info
export enum SDGKUBSGMFeeGroup {
	VETERANS = 1,
	ENGLISH = 2,
	SPANISH = 3,
	ENGLISH_MEXICO = 4,
	SPANISH_MEXICO = 5,
	SPANISH_ESPANOL = 6,
	SPANISH_MEXICO_ESPANOL = 7,
}

export enum PAProgram {
	FIRST_PROGRAM = 10,
	PROFESSIONAL_MASSAGE = 11,
	BASIC_COMPUTING = 12,
}

// localhost values
// export enum SDGKUProgram {
//     MSCT_ESP = 14,
//     MSCT_HYBRID = 131,
//     MSCT = 7,
//     MSIM_HYBRID = 12,
//     MSIM = 13,
//     BSGM_HYBRID = 11,
//     BSGM_ESP = 10,
//     BSGM = 5,
//     FSDI_HYBRID = 9,
//     FSDI = 3,
// }

export const SDGKUProgramGroup = {
	allFSDIPrograms: [SDGKUProgram.FSDI, SDGKUProgram.FSDI_HYBRID],
	allBSGMPrograms: [SDGKUProgram.BSGM, SDGKUProgram.BSGM_ESP, SDGKUProgram.BSGM_HYBRID],
	allMSCTPrograms: [SDGKUProgram.MSCT, SDGKUProgram.MSCT_ESP, SDGKUProgram.MSCT_HYBRID],
	allMSIMPrograms: [SDGKUProgram.MSIM, SDGKUProgram.MSIM_HYBRID],
};

export const COBAProgramFromName = (programName: string): COBAProgram => {
	if (programName.toLowerCase().includes("hybrid")) return COBAProgram.BARBER_HYBRID;
	if (programName.toLowerCase().includes("barber")) return COBAProgram.BARBER;
	else if (programName.toLocaleLowerCase().includes("cosmet")) return COBAProgram.COSMETOLOGY;
	else return COBAProgram.ESTHETICIAN;
};

// DEPRECATED, DO NOT USE IT
export const SDGKUProgramFromName = (programName: string): SDGKUProgram => {
	if (programName.toLocaleLowerCase().includes("global")) return SDGKUProgram.BSGM;
	else if (programName.toLocaleLowerCase().includes("full")) return SDGKUProgram.FSDI;
	else if (programName.toLocaleLowerCase().includes("technology")) return SDGKUProgram.MSCT;
	else if (programName.toLocaleLowerCase().includes("technology")) return SDGKUProgram.MSIM;
	else return {} as SDGKUProgram;
};

export const PAProgramFromName = (programName: string): PAProgram => {
	if (programName.toLocaleLowerCase().includes("basic")) return PAProgram.BASIC_COMPUTING;
	else if (programName.toLocaleLowerCase().includes("professional")) return PAProgram.PROFESSIONAL_MASSAGE;
	else if (programName.toLocaleLowerCase().includes("first")) return PAProgram.FIRST_PROGRAM;
	else return {} as PAProgram;
};

export enum Permission {
	APPROVE_DOCUMENTS = "approve_documents",

	VIEW_ESTIMATE_TEMPLATES = "view_estimate_templates",
	EDIT_ESTIMATE_TEMPLATES = "edit_estimate_templates",

}