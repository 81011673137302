import React, { useContext, useEffect, useRef, useState } from 'react';
import { Workbook, WorkbookInstance } from '@fortune-sheet/react';
import { Sheet } from '@fortune-sheet/core';

import '@fortune-sheet/react/dist/index.css';
import './studentEstimate.scss';
import AdminControls from './adminControls';
import EstimateControls from './estimateControls';
import { Student } from '../../../models/Student.model';
import globalContext from '../../../context/globalContext';
import { Permission } from '../../../models/Enums';
import ErrorBundary from '../../shared/errorBoundary/errorBoundary';

const spreadSheetConfig = {
  currencySymbol: '$',
  decimalSeparator: '.',
  thousandSeparator: ',',
  dateFormat: 'MM/DD/YYYY',
  timeFormat: 'hh:mm A',
};

export interface IStudentEstimate {
  studentId: string | number;
  student?: Student;
}

const StudentEstimate: React.FC<IStudentEstimate> = ({ studentId, student }) => {
  const workbookRef = useRef<WorkbookInstance>(null);
  const componentRef = useRef(null);

  const { user, hasPermission } = useContext(globalContext);

  const [sheets, setSheets] = useState<Sheet[]>([{ name: 'Sheet1' }]);
  const [printing, setPrinting] = useState<boolean>(false);
  const [key, setKey] = useState(0);
  const [canEditTemplates] = useState<boolean>(hasPermission(Permission.EDIT_ESTIMATE_TEMPLATES));


  const handleSheetDataChange = (d: any) => {
    setSheets(d);
  };

  const onAfterPrint = () => {
    setPrinting(false);
  };

  const clearSheets = () => {
    for (let i = 0; i < sheets.length; i++) {
      workbookRef.current?.deleteSheet({ id: sheets[i].name });
    }
  };

  const onBeforePrint = () => {};

  return (
    <div ref={componentRef} className="student-estimate">
      <h5>Financial Aid Estimate</h5>

      <h4 className="print-only">{student?.firstName + ' ' + student?.lastName}</h4>

      <div className={!printing ? 'spreadsheet-container' : 'spreadsheet-container-print'}>
        <div className="controls">
          <EstimateControls studentId={studentId} printComponentRef={componentRef} workbookRef={workbookRef} sheets={sheets} setSheets={setSheets} onBeforePrint={onBeforePrint} onAfterPrint={onAfterPrint} />
          {canEditTemplates && <AdminControls printComponentRef={componentRef} workbookRef={workbookRef} sheets={sheets} setSheets={setSheets} />}
        </div>

        <ErrorBundary message="Select the template to try again">
          <Workbook row={40} column={12} key={key} ref={workbookRef} data={sheets} lang="en" config={spreadSheetConfig} onChange={handleSheetDataChange} />
        </ErrorBundary>
      </div>
    </div>
  );
};

export default StudentEstimate;
