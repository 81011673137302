import { FunctionComponent, useContext, useEffect, useState } from 'react';
import FileUpload, { FileUploadStatus } from '../../../components/shared/file-upload/fileUpload';
import globalContext from '../../../context/globalContext';
import { AlertType } from '../../../models/AlertType.enum';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { UploadRequestData } from '../../../models/uploadRequest.data';
import uploadRequestService from '../../../services/uploadRequest.service';
import { Document, DocumentType } from '../../../models/Document.model';
import { DocumentUploadType, DocumentStatus, UploadRequestStatus } from '../../../models/Enums';
import { Send as SendIcon } from 'react-feather';
import { toast } from 'react-toastify';

import './uploadRequestedDocuments.scss';

interface UploadBoxProps {
  token: string;
  studentId: number;
  documentId: number;
  documentType: DocumentType;
  onStatusChange: (status: FileUploadStatus) => void;
}

const UploadBox: FunctionComponent<UploadBoxProps> = (props) => {
  const [status, setStatus] = useState<FileUploadStatus>(FileUploadStatus.SELECT);

  const handleUpload = async (file: File) => {
    setStatus(FileUploadStatus.UPLOADING);

    let doc = {
      documentType: props.documentType.id,
      uploadType: DocumentUploadType.MANUAL_UPLOAD,
      file: file,
      fileName: file.name,
      fileSize: file.size,
      student: props.studentId,
      status: DocumentStatus.PENDING,
    } as Document;

    var resp = await uploadRequestService.uploadDocument(props.token, doc);
    if (resp?.status === HttpStatusCode.Ok) {
      setStatus(FileUploadStatus.COMPLETED);
      props.onStatusChange(FileUploadStatus.COMPLETED);
    } else {
      props.onStatusChange(FileUploadStatus.ERROR);
    }
  };

  return (
    <div className="upload-box">
      <h5>{props.documentType.name}</h5>
      <div className="upload-box__content">
        <FileUpload status={status} message="" reupload={true} onChange={handleUpload} onReuploadClick={() => setStatus(FileUploadStatus.SELECT)}></FileUpload>
      </div>
    </div>
  );
};

const UploadRequestedDocuments = (props: any) => {
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);

  const [token, setToken] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [data, setData] = useState<UploadRequestData>();
  const [pending, setPending] = useState(1);
  const [requestStatus, setRequestStatus] = useState<UploadRequestStatus>(UploadRequestStatus.VIEWED);

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    if (token) {
      setToken(token);
      loadUploadRequest(token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const loadUploadRequest = async (token: string) => {
    setLoading(true);
    const resp = await uploadRequestService.getUploadRequest(token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      console.log(resp.data as UploadRequestData);
      setData(resp.data);
      setPending(resp.data?.documents.length);
      setRequestStatus(resp.data?.request.status);
    } else if (resp?.status === HttpStatusCode.Forbidden) {
      setTokenError(true);
    }
  };

  const handleMarkCompleted = async () => {
    setLoading(true);
    const resp = await uploadRequestService.makeCompleted(token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      toast.dark('Upload Request Completed.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setRequestStatus(UploadRequestStatus.COMPLETED);
    }
  };

  const handleStatusChange = (status: FileUploadStatus) => {
    if (status === FileUploadStatus.COMPLETED) {
      setPending(pending - 1);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const pendingContent = () => (
    <>
      <div className="top-row">
        <div className="student-info">
          <h5>
            Dear {data?.request.student.firstName ?? ' student'} {data?.request.student.lastName}.
          </h5>
          {!tokenError ? (
            <h6>Please upload the following document(s) to continue with your application process.</h6>
          ) : (
            <h5>Your Token seems invalid or it expired, please get in contact with the university personnel to request a new one.</h5>
          )}
        </div>
      </div>

      <div className="document-list">
        {data?.documents.map((document) => (
          <UploadBox
            key={document.id}
            token={token}
            studentId={data?.request.student.id}
            documentId={document.id}
            documentType={document.documentType}
            onStatusChange={(status: FileUploadStatus) => handleStatusChange(status)}
          />
        ))}

        {pending < 1 && (
          <div className="controls">
            <button onClick={handleMarkCompleted} className="btn btn-primary">
              <SendIcon />
              I'm Done, Save My Documents
            </button>
          </div>
        )}
      </div>
    </>
  );

  const completedContent = () => (
    <div className="request-completed">
      <h3>The request has been completed.</h3>
      <h5>A school administrator will review your documents.</h5>
    </div>
  );

  const mainContent = () => (
    <>
      <div className="school">
        <img className="logo" src={'/static/schools/logos/' + (data?.request?.schoolId ?? 0) + '.png'} alt="School logo"></img>
      </div>

      <div className="documents">{requestStatus != UploadRequestStatus.COMPLETED ? pendingContent() : completedContent()}</div>
    </>
  );

  return <div className="upload-requested-documents">{mainContent()}</div>;
};

export default UploadRequestedDocuments;
