import { useContext, useEffect, useState } from "react";
import globalContext from "../../../../context/globalContext";
import { FinishPage, TokenError } from "../enrollment/sdgku.extrapages";
import { useForm } from "react-hook-form";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import { SDGKUFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import usePageSignature from "../../../../hooks/pageSignature";
import TextField from "../../../../components/shared/Input/textField";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { SDGKUProgram } from "../../../../models/Enums";
import { SignedForm } from "../../../../models/Forms.model";
import { Header } from "../header";
import dateService from "../../../../services/date.service";
import { CheckCircle as IconCheck } from "react-feather";

interface ExtraData {
    revisedDate: string;
    transferCredits: string;
    sdgkuCredits: string;
    pricePerCredit: string;
    tuition: number;
    fees: number;
    loaBegin: string;
    loaEnd: string;
}

interface AddendumForm {
    studentName: string;
    program: string;
    phone: string;
    endDate: string;
    startDate: string;
    address: string;
    revisedDate: string
}

const AddendumSpanish = (props: any) => {

    const [tokenError, setTokenError] = useState(false);
    const [viewController, setViewController] = useState<SignedPageData>(null!);
    const { saveForm, getSignature, setIsCustomValid, setRequiredCustomValidation } = usePageSignature(viewController);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const { setLoading, showAlert } = useContext(globalContext);

    const [extraData, setExtraData] = useState<ExtraData>({} as ExtraData);

    const {
        register,
        formState: { errors },
        setValue,
    } = useForm<AddendumForm>();

    const loadInitialData = async () => {
        // get token from url params
        const params = new URLSearchParams(props.location.search);
        const token = params.get("token");
        const sudentId = params.get("id");

        if (token && sudentId) {
            loadForm(+sudentId, token);
        } else {
            showAlert("Invalid token", AlertType.Error);
            setTokenError(true);
        }
    };

    const initializeViewController = (formData: SignedForm, program: SDGKUProgram, token: string) => {
        if (!formData) return;
        const address = `${formData.student.addressStreet || ""}, ${formData.student.addressCity || ""} ${formData.student.addressZip || ""}`;
        const studentName = formData.student.firstName + " " + formData.student.lastName;
        let programStartDate = "Loading...";
        let programEndDate = "Loading...";

        if (formData?.programStart) {
            programStartDate = dateService.formatYMD(formData.programStart);
        }

        if (formData?.programEnd) {
            programEndDate = dateService.formatYMD(formData.programEnd);
        }

        setValue("studentName", studentName);
        setValue("program", formData.programName);
        setValue("phone", formData.student.phone);
        setValue("address", address);
        setValue("startDate", programStartDate);
        setValue("endDate", programEndDate);

        formData?.formData.forEach((item) => {
            if (item.key !== "officer_signature") {
                setExtraData((prev) => ({ ...prev, [item.key]: item.value }));
            }
        });

        setIsCustomValid(true);
        setRequiredCustomValidation(true);
        setViewController(new SignedPageData(SignedFormKey.ADDENDUM_SPANISH, program, formData, token, setEnableSubmit));
    };

    const loadForm = async (studentId: number, token: string) => {
        setLoading(true);
        try {
            const resp = await signedFormService.getFormData(studentId, SignedFormKey.ADDENDUM_SPANISH, token);
            if (resp?.status === HttpStatusCode.Ok) {
                const program: SDGKUProgram = resp.data.programId;
                initializeViewController(resp?.data, program, token);
            } else if (resp?.status === HttpStatusCode.Forbidden) {
                showAlert("Invalid token", AlertType.Error);
                setTokenError(true);
            }
        } catch {
            showAlert("Unexpected error", AlertType.Error);
            setTokenError(true);
        } finally {
            setLoading(false);
        }
    };

    const submitForm = async () => {
        if (await saveForm(SDGKUFormsPage.Addendum_spanish, true)) {
            setCompleted(true);
        } else {
            showAlert("Error while saving. Please verify all field are completed", AlertType.Error);
        }
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    if (tokenError) return <TokenError />;

    if (completed)
        return (
            <>
                <div className="doc-area">
                    <Header />
                    <FinishPage pageMessage="ANEXO AL ACUERDO DE INSCRIPCIÓN" />
                </div>
            </>
        );

    return (
        <div className="benefitCoding mx-auto p-3 doc-area">
            <Header />
            <div className="doc-content pt-3">
                <h3 className="title">ANEXO AL ACUERDO DE INSCRIPCIÓN</h3>

                <div className="row">
                    <div className="col-6">
                        <TextField disabled errors={errors} label="Nombre del Estudiante" field="studentName" type="text" register={register} />
                    </div>
                    <div className="col-6">
                        <TextField disabled errors={errors} label="Programa" field="program" type="text" register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <TextField disabled errors={errors} label="Dirección" field="address" type="text" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Teléfono" field="phone" type="text" register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Fecha de Inicio" field="startDate" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Fecha de Finalización" field="endDate" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Fecha de Finalizacion Revisada" field="revisedDate" value={extraData?.revisedDate || "Loading..."} type="text" register={register} />
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Créditos de Transferencia Aceptados (Semestre)</b>
                        </div>
                        <div className='col-3 text-right'>
                            {extraData?.transferCredits}
                        </div>
                    </div>

                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Créditos SDGKU para Gradaurse (Semestre)</b>
                        </div>
                        <div className='col-3 text-right'>
                            {extraData?.sdgkuCredits}
                        </div>
                    </div>

                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Precio Revisado por Crédito</b>
                        </div>
                        <div className='col-3 text-right'>
                            ${extraData?.pricePerCredit}
                        </div>
                    </div>

                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Matrícula Revisada $</b>
                        </div>
                        <div className='col-3 text-right'>
                            ${extraData?.tuition}
                        </div>
                    </div>

                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Tasas Revisadas</b>
                        </div>
                        <div className='col-3 text-right'>
                            ${extraData?.fees}
                        </div>
                    </div>
                </div>

                <br />
                <h5 className="sub-title">Permiso de Ausencia (LOA)</h5>

                <div className="container-fluid">
                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Fecha de Inicio de Ausencia</b>
                        </div>
                        <div className='col-3 text-right'>
                            {extraData?.loaBegin}
                        </div>
                    </div>
                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Fecha Final de Ausencia</b>
                        </div>
                        <div className='col-3 text-right'>
                            {extraData?.loaEnd}
                        </div>
                    </div>
                </div>
                <br />
                <div className="container-fluid">
                    <div className="row mb-2 align-items-end">
                        <div className='col-4'>
                            <b>Fecha de Finalización del Acuerdo Revisada</b>
                        </div>
                        <div className='col-3 text-right'>
                            {new Date().toLocaleDateString()}
                        </div>
                    </div>
                </div>

                <div className="text-container">
                    <div className="position-relative d-flex flex-row">
                        <div className="pad">{getSignature()}</div>
                    </div>

                    <div className="alert alert-success row ">
                        <IconCheck className='icon col-1' />
                        Este documento ha sido firmado por un funcionario de la escuela.
                    </div>
                    <div className="text-center pb-2 mb-3 mt-3">
                        <button disabled={!enableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
                            Guardar y Completar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddendumSpanish;
