import emailService from "../../services/email.service";
import "./notification.scss";
import { useHistory } from "react-router-dom";

const NotificationMessage = (props: any) => {
  const history = useHistory();
  const el = document.getElementById("notification-dashboard-container"); // id of the chat container
  if (el) {
    el.scrollTop = el.scrollHeight;
  }

  const formatDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString("en-US");
  };

  const readMessage = async () => {    
    await emailService.readMessage(props.data.id);
    history.push({
      pathname: `/student/overview/${props.data.student.id}`,
      state: { fromNotification: true },
    });
  };

  return (
    <a className="notification-dashboard" onClick={readMessage}>
      <div className="img-notification"></div>
      <div className="notification-body">
        <div className="notification-content">
          <p className="notification-name">{props.data.student.fullName}</p>
          <span className="date">{formatDate(props.data.updatedAt)}</span>
        </div>
        <p className="notification-school">
          {props.data.school ? props.data.school : "Unknown School"}
        </p>
        <p className="notification-subject">{props.data.subject}</p>
      </div>
    </a>
  );
};

export default NotificationMessage;
