import { useContext, useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import formService from '../../../services/applicationRequest.service';
import studentService from '../../../services/student.service';
import Modal from '../../../components/modal/modal';
import globalContext from '../../../context/globalContext';
import commonService from '../../../services/common.service';
import InputMask from 'react-input-mask';

import { ApplicationStatus, SchoolID, StudentStatus } from '../../../models/Enums';
import { ApplicationForm } from '../../../models/ApplicationForm.model';
import { AlertType } from '../../../models/AlertType.enum';
import { State } from '../../../models/common/State.model';

import { Program, ProgramStartDate } from '../../../models/Program.model';
import programsService from '../../../services/programs.service';

import './preApplicationRequest.scss';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { UserMessages } from '../../../components/shared/constants/constants';

import { maritalStatusOptions } from '../commonOptions';

const PreApplicationRequest = (props: any) => {
	let sigCanvas: any = useRef(null);
	const { clearAlert, showAlert, setLoading } = useContext(globalContext);

	const [form, setForm] = useState(new ApplicationForm());
	const [modalVisible, setModalVisible] = useState(false);

	const [states, setStates] = useState<State[]>([]);
	const [duplicatedEmail, setDuplicatedEmail] = useState(false);
	const [isButtonClicked, setIsButtonClicked] = useState(false);

	const [programs, setPrograms] = useState<Program[]>([]);
	const [selectedProgram, setSelectedProgram] = useState<Program>();
	const [schedule, setSchedule] = useState([] as any);

	const componentDidMount = async () => {
		let respStates = await commonService.getStates();
		setStates(respStates);
	};

	useEffect(() => {
		componentDidMount();
		loadInitialData();
	}, []);

	const handleSignatureClear = () => {
		if (sigCanvas.current) {
			sigCanvas?.current?.clear();
		}
	};

	const handleEmailChanged = async (e: any) => {
		let response = await studentService.validateEmail(e.target.value);
		if (response?.status !== HttpStatusCode.Ok) {
			setDuplicatedEmail(true);
			showAlert(UserMessages.errors.student_duplicated_email, AlertType.Error);
		} else {
			setDuplicatedEmail(false);
			clearAlert();
		}
	};

	const handleInputChange = (event: any) => {
		const copyForm = { ...form } as any;
		let name = event.target.name;
		if (name.includes('student.')) {
			name = name.replace('student.', '');
			copyForm.student[name] = event.target.value;
		} else {
			copyForm[event.target.name] = event.target.value;
		}

		setForm(copyForm);
		if (name === 'email') {
			validateEmail(event.target.value);
		}
	};

	const validateEmail = async (email: string) => {
		let response = await studentService.validateEmail(email);
		if (response?.status !== HttpStatusCode.Ok) {
			showAlert(UserMessages.errors.student_duplicated_email, AlertType.Error);
		} else {
			clearAlert();
		}
	};

	const handleCheckChange = (event: any) => {
		const copyForm = { ...form } as any;
		copyForm[event.target.name] = event.target.checked;
		setForm(copyForm);
	};

	const showErrors = (errors: string[]) => {
		let errorList = errors.map((error: string, index: number) => {
			return <li key={index}>{error}</li>;
		});

		let alert = (
			<div>
				<h4>Please fix the following issues and try again</h4>
				<ul className="error-list">{errorList}</ul>
			</div>
		);

		showAlert(alert, AlertType.Error);
	};

	const handleModalClose = () => {
		setModalVisible(false);
		props.history.push(`/public/SCCBB/thank_you`);
	};

	const handleModalAccept = async () => {
		setModalVisible(false);
	};

	// TODO: change to a react form validation option
	const validateForm = () => {
		let errors: string[] = [];

		if (sigCanvas.current.isEmpty()) errors.push('Signature is required');

		if (!form.student.firstName) errors.push('First Name is required');
		if (!form.student.lastName) errors.push('Last Name is required');

		if (!form.student.addressStreet) errors.push('Street is required');
		if (!form.student.addressCity) errors.push('City is required');
		if (!form.student.addressState) errors.push('State is required');
		if (!form.student.addressZip) errors.push('Zip Code is required');

		if (!form.student.phone) errors.push('Cell/Home phone is required');
		if (!form.student.maritalStatus) errors.push('Marital Status is required');
		if (!form.student.birthDate) errors.push('Date of Birth is required');

		if (!form.student.email) errors.push('Email is required');
		if (!form.student.educationLevel) errors.push('Educational Background is required');
		if (!form.student.lastSchool) errors.push('Last school attended is required');

		if (!form.program) errors.push('Program of interest is required');
		if (!form.hearAboutUs) errors.push('Hear about us is required');
		if (!form.hearAboutUsOther && form.hearAboutUs === 'other') errors.push('Specify how did you hear about us');
		if (!form.whyChooseUs) errors.push('Why did you choose this field us is required');
		if (!form.educationalGoals) errors.push('Educational goals field us is required');
		if (!form.whyAttendSchool) errors.push('Why attend the school field us is required');
		if (!form.howEnsureCompletion) errors.push('How ensure completion field us is required');

		if (form.currentlyEmployed === '1' && !form.employedWhere) errors.push('Specify where are you employed');
		if (!form.payCash && !form.payFinancialAid && !form.payMilitaryBenefits) errors.push('Specify at least 1 financial option');

		return errors;
	};

	const resetForm = () => {
		setForm(new ApplicationForm());
	};

	const handleSave = async () => {
		let errors = validateForm();
		if (errors.length) {
			showErrors(errors);
			return;
		}
		clearAlert();
		setIsButtonClicked(true);
		setLoading(true, 'Saving, please wait');

		let payload = {
			...form,
			status: StudentStatus.APPLICATION_REQUEST,
		} as ApplicationForm;

		console.log(payload);

		payload.student.startDate = undefined;

		// todo: move this hardcoded school id to a utility function
		payload.student.school = SchoolID.SCCBB;
		payload.student.status = StudentStatus.APPLICATION_REQUEST;
		payload.status = ApplicationStatus.SUBMITTED;
		payload.signature = sigCanvas.current.toDataURL();

		setLoading(true, 'Saving, please wait');
		let response = await formService.saveApplicationRequest(payload);
		setLoading(false);
		if (response) {
			resetForm();
			setModalVisible(true);
			if (sigCanvas.current) {
				sigCanvas?.current?.clear();
			}
			setIsButtonClicked(false);
		}

		setLoading(false);
	};

	const loadInitialData = async () => {
		let statesReq = commonService.getStates();

		let programsReq = programsService.getProgramsBySchool(SchoolID.SCCBB);
		let scheduleReq = programsService.getScheduleDaysBySchool(SchoolID.SCCBB);
		setLoading(true, 'Loading data...');
		let [statesRes, programsRes,scheduleRes] = await Promise.all([
			statesReq,
			programsReq,
			scheduleReq,
		]);
		
		setLoading(false);
		setStates(statesRes);
		setPrograms(programsRes?.data ?? []);
		setSchedule(scheduleRes ?? []);
	};

	const getSchedule = () => {
		const programId = selectedProgram?.id;
		const daysOfWeek: { [key: number]: string } = {
			0: 'Mon',
			1: 'Tue',
			2: 'Wed',
			3: 'Thu',
			4: 'Fri',
			5: 'Sat',
			6: 'Sun',
		};
		if (schedule.length > 0 && programId) {
			return schedule
				.filter((x: any) => x.programId === programId)
				.map((x: any) => {
				const day = Number(x.day);
				const dayText = daysOfWeek[day];
				const fromTimeText = x.fromTime;
				const toTimeText = x.toTime;
				return ` ${dayText}: ${fromTimeText} - ${toTimeText}`;
				});
		}
		return [];
	};

	const getDuration = () => {
		if(!selectedProgram || !selectedProgram.length) {
			return "";
		}
		return `${selectedProgram.length} ${selectedProgram.unit}`;
	};

	const handleProgramChange = (e: any) => {
		const selectedProgramId = e.target.value;
		const theProgram = programs.find(x => x.id == selectedProgramId);		
		setSelectedProgram(theProgram);
		setForm((prevState) => ({ ...prevState, program_id: selectedProgramId, program: (theProgram?.name || "") }));
	};

	return (
		<div className="preapp-form container">
			<Modal
				visible={modalVisible}
				cancelButtonText="Ok"
				cancelButtonClass="btn-primary"
				mainButtonText=""
				title="Application submitted"
				body="Thank you for your interest, an administrator will reach out to you shortly"
				onCancel={handleModalClose}
				onClose={handleModalClose}
				onAccept={handleModalAccept}
			></Modal>

			<h2>
				<img src="/static/sccbb/logo.png" alt="sccbb logo"></img>
				<br></br>
				<span className=" page-title">Pre-Application for Admission</span>
			</h2>

			<h3>Personal Information</h3>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">First name:</label>
					<input
						type="text"
						className="form-control"
						required
						id="fname"
						placeholder="Enter your first name"
						name="student.firstName"
						maxLength={70}
						value={form.student.firstName}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form-group">
					<label className="d-block">Last name:</label>
					<input
						type="text"
						className="form-control"
						placeholder="Enter your last name"
						name="student.lastName"
						maxLength={70}
						value={form.student.lastName}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">Street:</label>
					<input
						type="text"
						className="form-control"
						placeholder="Enter your street"
						name="student.addressStreet"
						maxLength={100}
						value={form.student.addressStreet}
						onChange={handleInputChange}
					/>
				</div>

				<div className="form-group">
					<label className="d-block">City:</label>
					<input
						type="text"
						className="form-control"
						placeholder="Enter your city"
						name="student.addressCity"
						maxLength={100}
						value={form.student.addressCity}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">State:</label>
					<select className="form-select" name="student.addressState" value={form.student.addressState} onChange={handleInputChange}>
						{states.map((state: State) => (
							<option key={state.id} value={state.id}>
								{state.name}
							</option>
						))}
					</select>
				</div>

				<div className="form-group">
					<label className="d-block">Zip Code:</label>
					<InputMask
						mask="99999"
						className="form-control"
						placeholder="Enter your zip code"
						name="student.addressZip"
						value={form.student.addressZip}
						onChange={handleInputChange}
					></InputMask>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">Cell/Home Phone:</label>
					<InputMask
						mask="999 999 9999"
						className="form-control"
						placeholder="Enter your cell/home phone"
						name="student.phone"
						value={form.student.phone}
						onChange={handleInputChange}
					></InputMask>
				</div>

				<div className="form-group">
					<label className="d-block">Work Phone:</label>
					<InputMask
						mask="999 999 9999"
						className="form-control"
						placeholder="Enter your work phone"
						name="student.workPhone"
						value={form.student.workPhone}
						onChange={handleInputChange}
					></InputMask>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">Marital Status:</label>
					<select className="custom-select" name="student.maritalStatus" value={form.student.maritalStatus} onChange={handleInputChange}>
						<option value="" disabled>
							-- Select an option --
						</option>
						{maritalStatusOptions.map((option: any) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>

				<div className="form-group">
					<label className="d-block">Date of Birth:</label>
					<input
						type="date"
						className="form-control"
						name="student.birthDate"
						value={form.student.birthDate}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">Email:</label>
					<input
						type="email"
						className="form-control"
						placeholder="Enter your email"
						name="student.email"
						maxLength={70}
						value={form.student.email}
						onChange={handleInputChange}
						onBlur={handleEmailChanged}
					/>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group">
					<label>Educational Background:</label>
					<select className="custom-select" name="student.educationLevel" value={form.student.educationLevel} onChange={handleInputChange}>
						<option value="" disabled>
							-- Select an option --
						</option>
						<option value="G.E.D">G.E.D</option>
						<option value="High School">High School diploma</option>
						<option value="Vocation School">Vocational School</option>
						<option value="Some College">Some College</option>
						<option value="College Degree">College Degree</option>
					</select>
				</div>

				<div className="form-group">
					<label className="d-block">What School did you last attend?</label>
					<input
						type="text"
						className="form-control"
						placeholder="Enter school name"
						name="student.lastSchool"
						maxLength={100}
						value={form.student.lastSchool}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<h3>Southern California College of Barber &#38; Beauty (SCCBB) Information</h3>
			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">Program of Interest:</label>
					<select className="custom-select" name="program" value={form.program_id} onChange={handleProgramChange}>
						<option value="" disabled>
							-- Select an option --
						</option>
						{programs.map((option: any) => (
							<option key={option.id} value={option.id}>
								{option.name}
							</option>
						))}
					</select>
				</div>

				<div className="form-group">
					<label className="d-block">Duration of the program:</label>
					<input
							type="text"
							className="form-control"
							placeholder="Select a program"
							name="courseLength"
							value={getDuration()}
							disabled
						/>
				</div>
			</div>
			<div className="form-group">
				<label className="d-block">Schedule:</label>
				<input
					type="text"
					className="form-control"
					placeholder="Enter the other option"
					name="schedule"
					maxLength={300}
					value={getSchedule() || ''}
					onChange={handleInputChange}
					disabled
				/>
			</div>

			<div className="form-group">
				<label className="d-block">How did you hear about SCCBB?</label>
				<div className="two-columns">
					<select className="custom-select" name="hearAboutUs" value={form.hearAboutUs} onChange={handleInputChange}>
						<option value="" disabled>
							-- Select an option --
						</option>
						<option value="online">Online</option>
						<option value="friend">Friend</option>
						<option value="other">Other</option>
					</select>
					<input
						type="text"
						className="form-control"
						placeholder="Enter the other option"
						name="hearAboutUsOther"
						maxLength={300}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className="form-group">
				<label className="d-block">Why did you choose this field?</label>
				<textarea
					className="form-control"
					placeholder="Enter your answer"
					name="whyChooseUs"
					value={form.whyChooseUs}
					onChange={handleInputChange}
					maxLength={255}
				></textarea>
				<p className="details">max 255 characters</p>
			</div>

			<div className="form-group">
				<label className="d-block">What are your educational goals?</label>
				<textarea
					className="form-control"
					placeholder="Enter your answer"
					name="educationalGoals"
					value={form.educationalGoals}
					onChange={handleInputChange}
					maxLength={255}
				></textarea>
				<p className="details">max 255 characters</p>
			</div>

			<div className="form-group">
				<label className="d-block">Why is now a good time for you to attend school?</label>
				<textarea
					className="form-control"
					placeholder="Enter your answer"
					name="whyAttendSchool"
					value={form.whyAttendSchool}
					onChange={handleInputChange}
					maxLength={255}
				></textarea>
				<p className="details">max 255 characters</p>
			</div>

			<div className="form-group">
				<label className="d-block">How will you ensure your completion of this program?</label>
				<textarea
					className="form-control"
					placeholder="Enter your answer"
					name="howEnsureCompletion"
					value={form.howEnsureCompletion}
					onChange={handleInputChange}
					maxLength={255}
				></textarea>
				<p className="details">max 255 characters</p>
			</div>

			<h3>Employment Background</h3>

			<div className="two-columns">
				<div className="form-group">
					<label className="d-block">Are you currently employed?</label>
					<div className="radios-group">
						<div className="form-check radio-element">
							<input className="form-check-input" type="radio" value="1" name="currentlyEmployed" onChange={handleInputChange} />
							<label className="form-check-label">Yes</label>
						</div>
						<div className="form-check radio-element">
							<input className="form-check-input" type="radio" value="0" name="currentlyEmployed" onChange={handleInputChange} />
							<label className="form-check-label">No</label>
						</div>
					</div>
				</div>

				<div className="form-group">
					<label className="d-block">If yes, where?</label>
					<input
						className="form-control"
						placeholder="Enter where"
						name="employedWhere"
						maxLength={200}
						value={form.employedWhere}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className="form-group row">
				<label>How do you plan to finance your education?</label>
				<div className="checks-group-payment row">
					<div className="form-check col">
						<input
							type="checkbox"
							className="form-check-input"
							checked={form.payFinancialAid}
							name="payFinancialAid"
							onChange={handleCheckChange}
						/>
						<label className="form-check-label">Financial Aid</label>
					</div>

					<div className="form-check col">
						<input type="checkbox" className="form-check-input" checked={form.payCash} name="payCash" onChange={handleCheckChange} />
						<label className="form-check-label">Cash</label>
					</div>

					<div className="form-check col">
						<input
							type="checkbox"
							className="form-check-input"
							checked={form.payMilitaryBenefits}
							name="payMilitaryBenefits"
							onChange={handleCheckChange}
						/>
						<label className="form-check-label">Military Benefits</label>
					</div>
				</div>
			</div>

			<div className="form-group">
				<label className="d-block">Are you currently attending another school?</label>
				<div className="radios-group">
					<div className="form-check radio-element">
						<input className="form-check-input" type="radio" value="1" name="currentlyStudying" onChange={handleInputChange} />
						<label className="form-check-label">Yes</label>
					</div>
					<div className="form-check radio-element">
						<input className="form-check-input" type="radio" value="0" name="currentlyStudying" onChange={handleInputChange} />
						<label className="form-check-label">No</label>
					</div>
				</div>
			</div>

			<div className="two-columns">
				<div className="form-group sig-pad">
					<label className="form-check-label">Please add your Signature</label>
					<SignaturePad ref={sigCanvas} canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}></SignaturePad>
				</div>
				<div className="clear-container">
					<button onClick={handleSignatureClear} className="btn btn-dark">
						Clear Signature
					</button>
				</div>
			</div>

			<div className="save-container">
				<h6 className="label-certify">I certify that the information stated above is true and correct.</h6>

				<button type="button" className="btn btn-primary" onClick={handleSave} disabled={duplicatedEmail || isButtonClicked}>
					Submit Application Request
				</button>
			</div>
		</div>
	);
};

export default PreApplicationRequest;
