import React, { FunctionComponent } from "react";
import "./actionIcon.scss";

interface ActionIconProps {
  icon: any;
  label?: string;
  color?: string;
  tooltip?: string;
  extraClass?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ActionIcon: FunctionComponent<ActionIconProps> = (props) => {
  const className = `
    ${props.extraClass || ""} 
    action-icon 
    ${props.color} 
    ${props.disabled ? "disabled" : ""}
  `.trim();

  return (
    <div 
      onClick={() => {!props.disabled && props.onClick?.()}} 
      className={className}
    >
      {props.icon}
      {props.label ? <label>{props.label}</label> : null}
    </div>
  );
};

export default ActionIcon;