import * as React from 'react';

import './disabilityButton.scss';
import accesibility from './img/accesibility.png';

const DisabilityButton = () => {
  return (
    <div
      className="disability-button-container"
      role="button"
      aria-label="Accessibility Information"
    >
      <button className="disability-button" aria-live="polite">
        <img
          src={accesibility}
          height="60px"
          width="60px"
          className="disability-icon"
          alt="Accessibility Information"
        />
        <span className="disability-button-text">
          If you have any special needs addressed by the Americans with
          Disabilities Act, please contact the Alliance for Student Aid
          Processing team at (855) 821-ASAP (2727).
        </span>
      </button>
    </div>
  );
};

export default DisabilityButton;
