import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { PATTERNS, MASKS } from "../../../components/shared/Input/patterns";
import SelectField from '../../../components/shared/Input/selectField';
import { CacheConstants } from '../../../services/constants.service';
import TitleBar from '../../../components/shared/titleBar/titleBar';
import TextField from '../../../components/shared/Input/textField';
import studentService from '../../../services/student.service';
import schoolService from '../../../services/school.service';
import globalContext from '../../../context/globalContext';
import { AlertType } from '../../../models/AlertType.enum';
import userService from '../../../services/user.service';
import { Student } from '../../../models/Student.model';
import { CountryId, StudentStatus, StudentStatusName } from '../../../models/Enums';
import { School } from '../../../models/School.model';
import { User } from '../../../models/User.model';
import './studentCreate.scss';

import { Briefcase as IconSchool, MapPin as IconMapPin, User as IconUser } from 'react-feather';
import { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { State } from '../../../models/common/State.model';
import commonService from '../../../services/common.service';
import { Country } from '../../../models/common/Country.model';



const StudentCreate = (props: any) => {
  const { clearAlert, setLoading, showAlert, user, getWithCache, clearCacheKey } = useContext(globalContext);
  const history = props.history;

  const [selectedStatus, setSelectedStatus] = useState(null as any);
  const [statusOptions, setStatusOptions] = useState([] as any[]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [reviewers, setReviewers] = useState([]) as any[];
  const [student, setStudent] = useState({} as Student);
  const [schools, setSchools] = useState([]) as any[];
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);

  // Used in edit mode
  const [editing, setEditing] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [isUSA, setIsUSA] = useState(true);

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
    setValue,
  } = useForm<Student>();


  const loadData = async () => {
    setLoading(true);
    let schoolPromise = getWithCache(CacheConstants.SCHOOLS, schoolService.getSchools);
    let reviewersPromise = getWithCache(CacheConstants.REVIEWERS, userService.getReviewers);
    let statesPromise = commonService.getStates();
    let countriesPromise = commonService.getCountries();
    let [statesRes, countriesRes, schoolsP, reviewersP] = await Promise.all([statesPromise, countriesPromise, schoolPromise, reviewersPromise]);

    setCountries(countriesRes);
    setStates(statesRes);
    setSchools(schoolsP);
    setReviewers(reviewersP);
    loadStatusOptions();
    let id = props.match.params.id;
    setStudentId(id);
    if (id) {
      setEditing(true);
      loadStudentData(id);
    } else {
      setLoading(false);
    }
  }


  const loadStudentData = async (id: string) => {
    let response = await studentService.getStudentById(id);
    if (response?.status === HttpStatusCode.Ok) {
      setStudent(response.data);
      let studentResp = response.data;
      setValue('firstName', studentResp.firstName);
      setValue('lastName', studentResp.lastName);
      setValue('email', studentResp.email);
      setValue('birthDate', studentResp.birthDate);
      setValue('ssn', studentResp.ssn || ""); // Temporary fix, if null, input fails
      setValue('phone', studentResp.phone);
      setValue('addressStreet', studentResp.addressStreet);
      // setValue('addressCountry', studentResp.addressCountry);
      setValue('addressState', studentResp.addressState);
      setValue('addressStateName', studentResp.addressStateName);
      setValue('addressCity', studentResp.addressCity);
      setValue('addressZip', studentResp.addressZip);
      setValue('startDate', formatDate(studentResp.startDate as string));
      setValue('comment', studentResp.comment);
      
      if (studentResp.addressCountry) {
        setIsUSA(studentResp.addressCountry === CountryId.USA);
      }
    }
    setLoading(false);
  }

  const formatDate = (originalDateString: string) => {
    if (!originalDateString) {
      return '';
    }
    let month = originalDateString.substring(0, 2);
    let day = originalDateString.substring(3, 5);
    let year = originalDateString.substring(6, 10);
    return year + '-' + month + '-' + day;
  };


  const saveStudent = async (data: Student) => {
    clearAlert();
    setButtonDisabled(true);
    setLoading(true);
    let studentData = data;
    clearAlert();
    clearCacheKey(CacheConstants.STUDENTS);
    if (!editing) {
      let response = await studentService.saveStudent(studentData);
      setLoading(false);
      if (response?.status === HttpStatusCode.Created) {
        showSuccessMessage(response?.data?.id);
      }
    } else {
      let response = await studentService.updateStudent(student.id, studentData);
      setLoading(false);
      if (response?.status === HttpStatusCode.Ok) {
        showSuccessMessage(studentId);
        // setButtonDisabled(false);
        setStudentId(0);
        history.push('/student/overview/' + studentId);
      }
    }
  }


  const multiSchool = () => {
    return user.isReviewer || user.isAdmin;
  }


  const getGroupClasses = () => {
    return multiSchool() ? 'three-columns' : 'two-columns';
  }


  const getSchoolGroup = () => {
    return multiSchool() ? (
      <SelectField
        parentClass='form-group'
        field='school'
        label='School'
        placeHolder='Select or Search School'
        errors={errors}
        control={control}
        disabled={editing}
        options={schools?.map((x: School) => ({ value: x.id, label: x.name }))}
        initialValue={student.school}
      // onChange={(selectedSchool: SelectOption) => setSelectedSchool(selectedSchool)}
      />
    ) : null;
  }


  const loadStatusOptions = () => {
    let allowedStatus = [StudentStatus.APPLICATION_REQUEST, StudentStatus.APPLICATION_ALLOWED, StudentStatus.ENROLLED, StudentStatus.ACTIVE, StudentStatus.GRADUATED, StudentStatus.CANCELLED, StudentStatus.WITHDRAWN, StudentStatus.LEAVE_OF_ABSENCE];
    let statusOptions = [] as any[];
    for (let i = 0; i < allowedStatus.length; i++) {
      statusOptions.push({ value: allowedStatus[i], label: StudentStatusName(allowedStatus[i]) });
    }
    statusOptions = statusOptions.sort((a, b) => a.label.localeCompare(b.label));
    setStatusOptions(statusOptions);
  }


  const getStudentStatus = () => {
    if (selectedStatus) {
      return { value: selectedStatus, label: StudentStatus[selectedStatus] };
    } else {
      return { value: student?.status, label: StudentStatus[student?.status] };
    }
  }


  const showSuccessMessage = (studentId: number) => {
    let message = editing ? 'Student updated successfully' : 'Student created successfully';
    toast.dark('✔️ ' + message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });

    showAlert(
      <div>
        <h5>{message}</h5>
        <Link to={'/student/overview/' + studentId}>Go to student overview</Link>
      </div>,
      AlertType.Success
    );

    reset();
    setTimeout(() => {
      clearAlert();
    }, 8000);
  }


  const validateEmail = async (email: string) => {
    let response = await studentService.validateEmail(email);
    if (response?.status !== HttpStatusCode.Ok) {
      const error = (
        <>
          <h5>Student Already Exist</h5>
          <p>
            There is an student on the system with the same email. <br />
            Please search the student on the student list. Do not duplicate the record.
          </p>
        </>
      );

      showAlert(error, AlertType.Error);
    } else {
      clearAlert();
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <TitleBar header={editing ? 'Register Student' : 'Edit Student'}></TitleBar>
      <div className='page create-student-page container pd-t-10'>
        <legend>
          <IconUser className='icon' /> Personal Information
        </legend>
        <div className="two-columns">
          <TextField
            parentClass='form-group'
            field='firstName'
            maxLength='70'
            type='text'
            label='First Name'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your first name'
          />
          <TextField
            parentClass='form-group'
            field='lastName'
            maxLength='70'
            type='text'
            label='Last Name'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your last name'
          />
        </div>

        <div className={getGroupClasses()}>
          {getSchoolGroup()}
          <TextField
            parentClass='form-group'
            field='email'
            type='email'
            label='Email'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your email'
            onChange={(e: any) => validateEmail(e.target.value)}
          />
          <SelectField
            parentClass='form-group'
            field='status'
            label='Status'
            placeHolder='Select a status'
            errors={errors}
            initialValue={student.status}
            control={control}
            options={statusOptions}
          />
        </div>

        <div className='three-columns'>
          <TextField
            parentClass='form-group'
            field='birthDate'
            type='date'
            label='Date of Birth'
            register={register}
            errors={errors}
            rules={{ required: true }}
          />
          <TextField
            parentClass='form-group'
            field='ssn'
            type='text'
            label='SSN'
            mask={MASKS.ssnMask}
            register={register}
            errors={errors}
            rules={{ required: true, pattern: PATTERNS.ssnRegEx, }}
            placeholder='Enter your SSN'
          />
          <TextField
            parentClass='form-group'
            field='phone'
            type='text'
            maxLength='20'
            label='Phone Number'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your phone number'
          />
        </div>
        <legend>
          <IconMapPin className='icon' /> Address Information
        </legend>
        <div className='two-columns'>
          <TextField
            parentClass='form-group'
            field='addressStreet'
            type='text'
            label='Street'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your street'
          />
          <TextField
            parentClass='form-group'
            field='addressCity'
            type='text'
            label='City'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your city'
          />
        </div>
        <div className='three-columns'>
          {
            isUSA ? (
              <SelectField
                field=".addressState"
                label="State"
                errors={errors}
                control={control}
                initialValue={student.addressState}
                rules={{ required: true }}
                labelKey="name"
                valueKey="id"
                options={states}
              />
            ) : (
              <TextField
                field="addressStateName"
                maxLength="100"
                label="State"
                placeholder="Enter your state"
                type="text"
                errors={errors}
                register={register}
              />
            )
          }

          <TextField
            parentClass='form-group'
            field='addressZip'
            maxLength='10'
            type='text'
            label='Zip'
            register={register}
            errors={errors}
            rules={{ required: true }}
            placeholder='Enter your Zip code'
          />

          <SelectField
            field="addressCountry"
            label="Country"
            errors={errors}
            control={control}
            rules={{ required: true }}
            labelKey='name'
            valueKey='id'
            initialValue={student.addressCountry}
            options={countries}
          />
        </div>
        <legend className='bg-gray-100'>
          <IconSchool className='icon' /> More Information
        </legend>
        <div className='two-columns'>
          <TextField
            parentClass='form-group'
            field='startDate'
            type='date'
            label='Start Date'
            register={register}
            errors={errors}
            rules={{ required: true }}
          // value={formatDate(student.startDate as string)}
          />
          <SelectField
            parentClass='form-group'
            field='reviewer'
            label='Reviewer'
            placeHolder='Select a reviewer'
            errors={errors}
            control={control}
            initialValue={student.reviewer}
            // onChange={(selectedReviewer: SelectOption) => setSelectedReviewer(selectedReviewer)}
            options={reviewers?.map((x: User) => ({ value: x.id, label: x.firstName + ' ' + x.lastName }))}
          />
        </div>
        <TextField
          parentClass="form-group"
          field="comment"
          type="text"
          multiLine={true}
          label="Comments "
          placeholder="Enter any comment"
          register={register}
          errors={errors}
        />

        <button disabled={buttonDisabled} onClick={handleSubmit((d) => saveStudent(d))} className='btn btn-primary px-5 mb-3'>
          {editing ? 'Update ' : 'Create '}
          Student
        </button>
      </div>
    </>
  );
}

export default StudentCreate;
