import { useContext, useEffect, useState } from "react";
import { Student } from "../../../../models/Student.model";
import globalContext from "../../../../context/globalContext";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import usePageSignature from "../../../../hooks/pageSignature";
import { useForm } from "react-hook-form";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { SCCBBFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { SignedForm, SignedFormStudent } from "../../../../models/Forms.model";
import './exitCounselingSccbb.scss';


interface ExitCounselingFormSCCBB {
  student: Student;
}

const ExitCounselingSCCBB = (props: any) => {
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { saveForm, getSignature, getInitials } = usePageSignature(viewController);
  const [tokenError, setTokenError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [todayDate, setTodayDate] = useState<string>(new Date().toLocaleDateString());
  const [student, setStudent] = useState<SignedFormStudent>(null!);

  const { register, formState: { errors, isValid }, setValue, getValues } = useForm<ExitCounselingFormSCCBB>();

  const loadInitalData = async () => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    const studentId = params.get('id');

    if (token && studentId) {
      loadForm(+studentId, token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    };
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    try {
      const resp = await signedFormService.getFormData(studentId, SignedFormKey.EXIT_COUNSELING_SCCBB, token);
      if (resp?.status == HttpStatusCode.Ok) {
        const programId = resp.data.programId;
        initializeViewController(resp.data, programId, token);
      }
    } catch {
      showAlert('Error loading form', AlertType.Error);
      setTokenError(true);
    } finally {
      setLoading(false);
    };
  };

  const initializeViewController = (formData: SignedForm, programId: number, token: string) => {
    if (!formData) return;

    setValue('student.firstName', formData.student.firstName);
    setStudent(formData.student);
    setViewController(new SignedPageData(SignedFormKey.EXIT_COUNSELING_SCCBB, programId, formData, token, setEnableSubmit));
  };

  const onSubmit = async () => {
    if (await saveForm(SCCBBFormsPage.ExitCounselingForm, true)) {
      setCompleted(true);
    } else {
      showAlert('Error while saving. Please verify all fields and try again', AlertType.Error);
    }
  }

  useEffect(() => {
    loadInitalData();
  }, []);


  const PageContent = () => (
    <div className="content">
      <div className="row">
        <div className="col-12 student-info">
          <p className="date">{todayDate}</p>
          <p className="student-name">{student?.firstName} {student?.lastName}</p>
          <p className="address-">{student?.addressStreet}, {student?.addressCity}, {student?.addressState}, {student?.addressZip}</p>
          <p className="student-first-name">{student?.firstName},</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="">
            Thank you for attending Southern California College of Barber and Beauty (SCCBB)! As a student loan borrower
            who has graduated, left school, or dropped below half-time, you must complete Direct Loan Exit Counseling. You
            have the right to know everything about your federal student loan(s), including repayment information, deferment(s),
            consolidation, and the consequences of default.
          </p>
          <br />
          <p>To satisfy the requirement, complete the Direct Loan Exit Counseling by visiting the website
            <a href="https://studentaid.gov/exit-counseling/">https://studentaid.gov/exit-counseling/</a> sign in, and make sure to use the same FSA ID that you used to log in to the
            FAFSA® application. If you do not remember your FSA ID, visit
            <a href="https://studentaid.gov/fsa-id/sign-in/retrieve-username">https://studentaid.gov/fsa-id/sign-in/retrieve-username</a> for instructions on how to retrieve your username. Once you
            complete the Direct Exit Counseling online, SCCBB will receive the results electronically. As an alternative, you
            may complete pages 40 and 41 of the Exit Counseling Guide and return it to the Administration Office.
          </p>
          <br />
          <p>For a quick reference on more information about Direct Loan Exit, visit this link for the Direct Loan Exit Counseling
            Guide: <a href="https://studentaid.gov/sites/default/files/exit-counseling.pdf">https://studentaid.gov/sites/default/files/exit-counseling.pdf</a>
          </p>
          <br />
          <p>To find out which loan servicer you were assigned as well as review all Direct Loan disbursement activity (NSLDS),
            go to <a href="https://studentaid.gov">https://studentaid.gov</a> .
          </p>
          <br />
          <p>Don't hesitate to get in touch with us with any questions or concerns.</p>
          <br />
          <p>Thank you,</p>
          <p>Southern California College of Barber and Beauty Office of Financial Aid</p>
          <br />
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>Office of Financial Aid</p>
          <p>School Phone: (760) 294-6405</p>
          <p>Email: info@sccbb.edu</p>
          <p>641 N. Broadway</p>
          <p>Escondido, CA 92025</p>
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {getSignature()}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <button type="button" className="btn btn-primary btn-submit" onClick={onSubmit} disabled={!enableSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )

  const TokenError = () => (
    <div className="content">
      <div className="row">
        <div className="col-12">
          <h3>Invalid Token</h3>
        </div>
      </div>
    </div>
  )

  const Completed = () => (
    <div className="alert alert-success mx-5" role="alert">
      <h4 className="alert-heading">Form completed</h4>
      <p>Thank you for completing SCCBB's Exit Counseling Form!</p>
    </div>
  )

  return (
    <div className="mx-auto p-3 doc-area">
      <div className="header-form">
        <div className="image">
          <img src="/static/sccbb/logo.png" alt="sccbb-logo" />
        </div>
        <div className="title">
          <h1>Exit Counseling</h1>
        </div>
      </div>
      <hr />
        {tokenError ? <TokenError /> : null}
        {completed ? <Completed /> : null}
        {!tokenError && !completed ? <PageContent /> : null}
      <div className="footer-form">
        <p>641 N. Broadway • Escondido, CA 92025 • (760) 294-6405. • www.sccbb.edu</p>
      </div>

    </div>
  );
}

export default ExitCounselingSCCBB;