import React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import Modal from '../../modal/modal';
import TextField from '../../shared/Input/textField';
import { PATTERNS } from '../../shared/Input/patterns';
import { Student } from '../../../models/Student.model';
import emailService from '../../../services/email.service';
import { User } from '../../../models/User.model';
import FileField from '../../../pages/emails/student-emails/fileField';
import Autocomplete from '@mui/material/Autocomplete';
import TextFieldMultiple from '@mui/material/TextField';

interface IEmailSendingModalProps {
  visible?: boolean;
  onSent?: () => void;
  close: () => void;
  student: Student;
  user: User;
}
const EmailSendingModal = ({
  visible = false,
  onSent,
  close,
  student,
  user,
}: IEmailSendingModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [studentEmail, setStudentEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [emailUser, setEmailUser] = useState<string[]>([]);

  const handleSend = async (form: any) => {
    const res = await emailService.sendUserEmail(form);
    if (res?.status === HttpStatusCode.Ok) {
      toast.dark('Message Sent', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      onSent?.();
      close();
    } else {
      toast.error('❌ Error Sending Message', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const loadData = async () => {
    try {
      const response = await emailService.getUsersEmailsBySchool(user.schools);
      if (response?.status === HttpStatusCode.Ok) {
        setEmailUser(response.data.map((student: any) => student.email));
      }
    } catch (error) {
      console.error('Error loading users:', error);
    }
  };

  useEffect(() => {
    if (student) {
      setStudentEmail(student.email);
      setValue('to', student.email);
      setValue('student_id', student.id);
      loadData();
    }
  }, [user, student]);

  const body = () => {
    return (
      <form method="POST" className="modal-new-upload-request ps-1">
        <TextField
          field="to"
          type="text"
          label="To"
          placeholder="Enter the recipient email address"
          register={register}
          errors={errors}
          value={studentEmail}
          onChange={(e) => setStudentEmail(e.target.value)}
          rules={{
            required: true,
            pattern: PATTERNS.emailRegEx,
          }}
        />

        <div className="reminder-container mb-2">
          <label className="d-block ps-1">CC:</label>
          <Controller
            name="cc"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                freeSolo
                limitTags={3}
                id="tags-outlined"
                size="small"
                options={emailUser}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextFieldMultiple
                    {...params}
                    // label="CC"
                    placeholder="Add emails"
                    helperText="You can add multiple emails by typing and pressing Enter"
                    // sx={{
                    //   "& .MuiOutlinedInput-root": {
                    //     "& fieldset": {
                    //       borderColor: "var(--bs-border-color)",
                    //       transition:
                    //         "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    //     },
                    //     // "&:hover fieldset": {
                    //     //   borderColor: "#b3b3b3", // Borde más claro al hacer hover
                    //     // },
                    //     "&.Mui-focused fieldset": {
                    //       borderColor: "None", // Borde más claro cuando está enfocado
                    //     },
                    //   },
                    // }}

                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          color: 'aqua',
                          borderColor: 'var(--bs-border-color)',
                          transition:
                            'border-color 0.15s ease-in-out, box-shadow(0, 0, 0, 0.7) 0.15s ease-in-out', // Transición de color de borde y sombra
                        },
                        '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                          color: 'rgba(0, 3, 8, 1)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'var(--bs-border-color)',
                        },
                        '&.Mui-focused fieldset': {
                          rderColor: 'rgb(132, 178, 245)',
                          boxShadow: '0 0 6px 3px rgba(132, 178, 245, 1)',
                        },
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </div>

        <TextField
          field="subject"
          type="text"
          label="Subject"
          placeholder="Enter the subject of the email"
          register={register}
          errors={errors}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          rules={{ required: true }}
        />

        <TextField
          field="message"
          type="text"
          multiLine={true}
          rows={10}
          label="Message"
          register={register}
          errors={errors}
          rules={{ required: true }}
        />

        <FileField
          field="files"
          register={register}
          setValue={setValue}
          errors={errors}
        />
      </form>
    );
  };

  return (
    <Modal
      body={body()}
      bodyTitle={<h5>New Message</h5>}
      bodySubtitle="Send a new Email to recipient"
      size="lg"
      visible={visible}
      flyout={true}
      mainButtonText="Send"
      onCancel={close}
      onClose={close}
      cancelButtonClass="btn-dark"
      onAccept={handleSubmit((d) => handleSend(d))}
    />
  );
};

export default EmailSendingModal;
