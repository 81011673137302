import http from "./http-common";
import { Program, ProgramShift, ProgramStartDate, ScheduleDay } from "../models/Program.model";

class ProgramService {
	async getPrograms() {
		try {
			let response = await http.get(`/programs/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error loading program list");
		}
	}

	async getProgramsBySchool(schoolId: string | number) {
		try {
			let response = await http.get<Program[]>(`/public/programs/school/${schoolId}/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Error retrieving programs");
		}
	}

	async addProgram(program: Program) {
		try {
			let response = await http.post("/programs/", program);
			return response;
		} catch (error) {
			http.fa360.displayError("Error Adding Program");
		}
	}

	async removeProgram(programId: string | number) {
		try {
			let response = await http.delete(`/programs/${programId}/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error Removing Program");
		}
	}

	async updateProgram(program: Program) {
		try {
			let response = await http.patch(`/programs/${program.id}/`, program);
			return response;
		} catch (error) {
			http.fa360.displayError("Error Updating Program");
		}
	}

	async getStartDates() {
		try {
			let response = await http.get("/startDates/");
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error getting Start Dates");
		}
	}

	async getStartDatesBySchool(schoolId: string | number) {
		try {
			let response = await http.get(`/public/startdates/school/${schoolId}/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error getting Start Dates");
		}
	}

	async addStartDate(programStartDate: ProgramStartDate) {
		try {
			let response = await http.post("/startDates/", programStartDate);
			return response;
		} catch (error) {
			http.fa360.displayError("Error Adding Start Date");
		}
	}

	async editStartDate(programStartDate:ProgramStartDate){
		try{
			let response = await http.patch(`/startDates/${programStartDate.id}/`, programStartDate);
			return response;
		}catch(error){
			http.fa360.displayError("Error Editing Start Date");
		}
	}

	async removeStartDate(programStartDateId: string | number) {
		try {
			let response = await http.delete(`/startDates/${programStartDateId}/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Error Removing Start Date");
		}
	}

	async getShifts() {
		try {
			let response = await http.get("/shifts/");
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error retrieving shifts");
		}
	}

	async getShiftsBySchool(schoolId: string | number) {
		try {
			let response = await http.get(`/public/shifts/school/${schoolId}/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error retrieving shifts");
		}
	}

	async addShift(shift: ProgramShift) {
		try {
			let response = await http.post("/shifts/", shift);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error adding shift");
		}
	}

	async removeShift(shiftId: string | number) {
		try {
			let response = await http.delete(`/shifts/${shiftId}/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error removing shift");
		}
	}
	
	async getScheduleDays() {
		try {
			let response = await http.get("/scheduleDays/");
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error fetching Schedule Days");
		}
	}

	async getScheduleDaysByProgram(programId: string | number) {
		try {
			let response = await http.get(`/scheduleDays/program/${programId}/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error retrieving shifts");
		}
	}

	async getScheduleDaysBySchool(schoolId: string | number) {
		try {
			let response = await http.get(`/scheduleDays/school/${schoolId}/`);
			return response.data;
		} catch (error) {
			http.fa360.displayError("Error retrieving shifts");
		}
	}

	async updateScheduleDays(editedDays: any[], programId: number, schoolId: number) {
		try {
			let response = await http.post(`/scheduleDays/batch_update/?program=${programId}&school=${schoolId}`, editedDays);
			return response;
		} catch (error) {
			http.fa360.displayError("Error updating Schedule Days");
		}
	}
}

export default new ProgramService();
