import './studentSideMenu.scss';
import signedFormService from '../../../services/signedForm.service';
import { SignedFormKey } from '../../../models/Forms.enum';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { toast } from 'react-toastify';
import { SchoolID, SDGKUBSGMFeeGroup, SDGKUProgram } from '../../../models/Enums';
import { useContext, useEffect, useState } from 'react';
import { trackFormSent } from '../../../log/eventTrackers';
import globalContext from '../../../context/globalContext';
import React from 'react';

interface IFormOption {
  text: string;
  key: SignedFormKey;
  feeGroup?: number; // for SDGKU BSGM, same program can have different set of fees
  withPrevData?: boolean;
  withBorder?: boolean;
}

interface ISendFormButtonProps {
  options: IFormOption[];
  text: string;
  cssClass?: string;
  onClick: (option: IFormOption) => void;
}

const StudentSideMenu = (props: any) => {
  const [formOptions, setFormOptions] = useState<IFormOption[]>([]);
  const [enrollmentAgreements, setEnrollmentAgreements] = useState<IFormOption[]>([]);
  
  const { user } = useContext(globalContext);

  const sendForm = async (key: SignedFormKey, feeGroup: number|undefined) => {    
    let res = await signedFormService.sendForm(props.student.id, key, feeGroup || 0);
    props.setUpdateData(true);
    processResponse(res);
  };

  const processResponse = (response: any) => {
    if (response?.status === HttpStatusCode.Created || response?.status === HttpStatusCode.Ok) {
      toast.dark('Email sent. You will recieve a copy of it.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast.error('There was a problem sending the email.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const buildSdgkuOptions = () => {
    // Enrollment Agreements
    let enrollments: IFormOption[] = [];
    let BSGMPrograms = [SDGKUProgram.BSGM, SDGKUProgram.BSGM_HYBRID, SDGKUProgram.BSGM_ESP];

    if (BSGMPrograms.includes(props.program?.id as SDGKUProgram)) {
      // BSGM Forms
      enrollments = [
        { text: '1 - BSGM Veterans (no waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT, feeGroup: SDGKUBSGMFeeGroup.VETERANS },
        { text: '2 - BSGM English (no waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT , feeGroup: SDGKUBSGMFeeGroup.ENGLISH },
        { text: '3 - BSGM Spanish (no waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT, feeGroup: SDGKUBSGMFeeGroup.SPANISH  },
        { text: '4 - BSGM English México (waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT, feeGroup: SDGKUBSGMFeeGroup.ENGLISH_MEXICO },
        { text: '5 - BSGM Spanish México (waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT, feeGroup: SDGKUBSGMFeeGroup.SPANISH_MEXICO },
 
        { text: '6 - BSGM Spanish Version Español (no waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT_SPANISH, withBorder: true },
        { text: '7 - BSGM Spanish México Version Español (waivers)', key: SignedFormKey.ENROLLMENT_AGREEMENT_SPANISH_2 },
      ];
    } else {
      // All other SDGKU Programs
      enrollments = [{ text: 'Enrollment Agreement', key: SignedFormKey.ENROLLMENT_AGREEMENT }];
    }

    setEnrollmentAgreements([
      ...enrollments,
      // put here forms for all programs
      { text: 'Enrollment Addendum', key: SignedFormKey.ADDENDUM, withPrevData: true, withBorder: true },
      { text: 'Enrollment Addendum (Spanish)', key: SignedFormKey.ADDENDUM_SPANISH, withPrevData: true },
    ]);

    // Other forms
    const allForms: IFormOption[] = [
      { text: 'Enrollment Forms (Wizard)', key: SignedFormKey.DISCLOSURE },
      { text: 'Fact Sheet', key: SignedFormKey.FACTSHEET },
    ];

    if (props.student.isVa) {
      allForms.push({ text: 'Transcript Evaluation Request', key: SignedFormKey.TRANSCRIPT_EVALUATION_REQUEST, withBorder: true });
      allForms.push({ text: 'Transcript Commitment Letter', key: SignedFormKey.TRANSCRIPTS_COMMITMENT });
      allForms.push({ text: 'VA Education Benefits Certification Request', key: SignedFormKey.VA_EDUCATION_BENEFIT_CERTIFICATION, withBorder: true });
      allForms.push({ text: 'VA Benefit Coding', key: SignedFormKey.BENEFIT_CODING });
      allForms.push({ text: 'Veterans Bulletin (VIB)', key: SignedFormKey.VIB });
      allForms.push({ text: 'VA Hybrid Statement Of Understanding', key: SignedFormKey.HYBRID_STATEMENT });      
    }

    setFormOptions(allForms);
  };

  const buildCobaOptions = () => {
    setEnrollmentAgreements([
      { text: 'Enrollment Agreement', key: SignedFormKey.ENROLLMENT_AGREEMENT },
      { text: 'Enrollment Addendum', key: SignedFormKey.ADDENDUM, withPrevData: true },
    ]);

    setFormOptions([
      { text: 'Disclosure Forms', key: SignedFormKey.DISCLOSURE },
      { text: 'FA Packet', key: SignedFormKey.FINANCIAL_AID },
      { text: 'Fact Sheet', key: SignedFormKey.FACTSHEET },
      { text: 'Ipad Disclosure Form', key: SignedFormKey.IPAD_DISCLOSURE },
      { text: 'Orientation Form', key: SignedFormKey.ORIENTATION_FORM },      
      { text: 'Exit Counseling', key: SignedFormKey.EXIT_COUNSELING }
    ]);
  };

  const buildSccbbOptions = () => {
    setFormOptions([
      { text: 'Disclosure Exit Counseling', key: SignedFormKey.EXIT_COUNSELING_SCCBB },
    ]);
  }

  useEffect(() => {
    switch (props.student?.school) {
      case SchoolID.COBA:
        buildCobaOptions();
        break;
      case SchoolID.SDGKU:
        buildSdgkuOptions();
        break;
      case SchoolID.SCCBB:
        buildSccbbOptions();
        break;
    }
  }, [props.student, props.program]);

  const showDialogForm = (formKey: string, title: string) => {
    props.openPrevModal(formKey, title);
  };

  const handleOptionClick = (option: IFormOption) => {    
    trackFormSent(user?.id as number, props.student.id, option.key, option.text);
    if (option.withPrevData) {
      showDialogForm(option.key, option.text);
    } else {
      sendForm(option.key, option.feeGroup);
    }
  };

  if (enrollmentAgreements.length + formOptions.length < 1) return null;

  return (
    <div className="student-card student-menu mg-t-10">
      <div className="card">
        <div className="card-header">
          <h6 className="mg-b-0">Send Electronic Forms</h6>
        </div>

        <SendFormButton options={formOptions} text="Send Enrollment Forms" onClick={handleOptionClick} />

        <SendFormButton options={enrollmentAgreements} text="Send Enrollment Agreement" cssClass="mb-4" onClick={handleOptionClick} />
      </div>
    </div>
  );
};

function SendFormButton(props: ISendFormButtonProps) {
  if (props.options.length < 1) return null;

  return (
    <div className={'btn-group mt-3 ' + props.cssClass} role="group" aria-label="Button group with nested dropdown">
      <div className="btn-group mx-auto" role="group">
        <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          {props.text}
        </button>
        <ul className="dropdown-menu">
          {props.options.map((option) => (
            <React.Fragment key={option.key}>
              {option.withBorder && <hr className="dropdown-divider" key={option.key + "-divider"} />}
              <li className="dropdown-item" key={option.key} onClick={() => props.onClick(option)}>
                {option.text}
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default StudentSideMenu;
