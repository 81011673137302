import TextField from "../../../../../components/shared/Input/textField"
import { AgreementProps } from "../../types"
import { useForm } from "react-hook-form"
import classNames from '../sdgku.enrollment.module.css'
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'
import dateService from '../../../../../services/date.service'
import moment from 'moment'


interface AgreementPage1StudentForm {
    firstName: string;
    middleName: string;
    lastName: string;
    ssn: string;
    birthDate: string;
    email: string;
    phone: string;

    addressStreet: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    addressCountry: string;
  }

interface AgreementPage1Form {
    student: AgreementPage1StudentForm;
    startDate: string;
    completionDate: string;
  }

export const AgreementPage1 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { register, formState: { errors }, setValue } = useForm<AgreementPage1Form>();
    const { saveForm, getAcknowledge, getInitials } = usePageSignature(data, false);

    let startDate = "Loading...";
    if(data?.formData?.programStart) {
        startDate = dateService.formatYMD(data.formData.programStart);
    }

    let completionDate = "Loading...";
    if(data?.formData?.programEnd) {
        completionDate = dateService.formatYMD(data.formData.programEnd);
    }


    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement1)
        },
    }))

    useEffect(() => {
        const timezone = moment.tz.guess();
        if (data && data.formData) {
            setValue("student.firstName", data.formData.student.firstName)
            setValue("student.middleName", data.formData.student.middleName)
            setValue("student.lastName", data.formData.student.lastName)
            setValue("student.ssn", data.formData.student.ssn?.substr(data.formData.student.ssn?.length - 4))

            if(data.formData.student.birthDate)
                setValue("student.birthDate", moment.tz(data.formData.student.birthDate, timezone).format('MM/DD/YYYY'))
            setValue("student.email", data.formData.student.email)
            setValue("student.phone", data.formData.student.phone)

            setValue("student.addressStreet", data.formData.student.addressStreet)
            setValue("student.addressCity", data.formData.student.addressCity)
            setValue("student.addressState", data.formData.student.addressState)
            setValue("student.addressZip", data.formData.student.addressZip)
            setValue("student.addressCountry", data.formData.student.addressCountry)

            setValue("startDate", startDate)
            setValue("completionDate", completionDate)
        }
    }, [])

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
            <h3>ENROLLMENT AGREEMENT</h3>
            <h5>
                {program?.programType} <br />
                {program?.programName}
            </h5>
            <div className={`container-fluid mb-3 form-group ${classNames.group_box}`}>
                <div className="row">
                    <div className="col-3">
                        <TextField disabled errors={errors} label="First Name" field="student.firstName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Middle Name" field="student.middleName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Last Name" field="student.lastName" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled maxLength="4" errors={errors} label="SSN (Last 4)" field="student.ssn" type="text" register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Birthday" field="student.birthDate" type="text" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Email" field="student.email" type="email" register={register} />
                    </div>
                    <div className="col-4">
                        <TextField disabled errors={errors} label="Phone #" field="student.phone" type="tel" register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Street Address" field="student.addressStreet" type="text" register={register} />
                    </div>
                    <div className="col-2">
                        <TextField disabled errors={errors} label="City" field="student.addressCity" type="text" register={register} />
                    </div>
                    <div className="col-2">
                        <TextField disabled errors={errors} label="State Abbr" field="student.addressState" type="text" register={register} />
                    </div>
                    <div className="col-2">
                        <TextField disabled errors={errors} label="Zip Code" field="student.addressZip" type="number" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Country" field="student.addressCountry" type="text" register={register} />
                    </div>
                </div>
            </div>
            <p className={`${classNames.group_box}`}>
                This agreement is a legally binding instrument when signed by the student and accepted by the school. Your signature on this agreement acknowledges that you have been given reasonable time to read and understand it and that you have been given: (a) a written statement of the refund policy including examples of how it applies, and (b) a catalog including a description of the course or educational service including all material facts concerning the school and the program of course of instruction which are likely to affect your decision to enroll. Immediately upon signing this agreement, you will be given a copy of it to retain for your records.
            </p>
            <p>                
                Prior to signing this enrollment agreement, you must be given a catalog or brochure and a School Performance Fact Sheet, which you are encouraged to review prior to signing this agreement. These documents contain important policies and performance data for this institution. This institution is required to have you sign and date the information included in the School Performance Fact Sheet relating to completion rates, placement rates, license examination passage rates, and salaries or wages, and the most recent three-year cohort default rate, if applicable, prior to signing this agreement.
            </p>
            <div className="position-relative">
                {getInitials('page1')}
            </div>
            <div className="mt-4 ps-4 position-relative">
                {getAcknowledge('certify', `
                    I certify that I have received the catalog, School Performance Fact Sheet, and information regarding completion rates, placement rates, license examination passage rates, and salary or wage information, and the most recent three-year cohort default rate, if applicable, included in the School Performance Fact sheet, and have signed, initialed, and dated the information provided in the School Performance Fact Sheet.
                    `)}
            </div>
            <div className="position-relative mt-4">
                {getInitials('page1', true)}
            </div>
            <div className="mt-4 ps-4">
                <div className="row align-items-center">
                    <p className='col-5'>Period covered by this enrollment agreement</p>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Program Start Date" field="startDate" type="text" register={register} />
                    </div>
                    <div className="col-3">
                        <TextField disabled errors={errors} label="Scheduled Completion Date" field="completionDate" type="text" register={register} />
                    </div>
                </div>
            </div>
        </div>
    )
})