import { useState, useContext, useEffect } from 'react';
import { SchoolID } from '../../../../models/Enums';
import globalContext from '../../../../context/globalContext';
import ProgramService from '../../../../services/programs.service';
import { Days, ProgramShift, ScheduleDay } from '../../../../models/Program.model';
import ActionIcon from '../../../shared/action-icon/actionIcon';
import { XCircle as IconReject } from 'react-feather';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import './cardScheduleView.scss';
import { is } from 'immutable';

const CardScheduleView = (props: any) => {
  const { isLoading, setLoading } = useContext(globalContext);
  const [programShifts, setProgramShift] = useState([] as ProgramShift[]);
  const [scheduleDays, setScheduleDays] = useState([] as ScheduleDay[]);
  const [editedScheduleDays, setEditedScheduleDays] = useState([] as ScheduleDay[]);

  const loadData = async () => {
    setLoading(true);

    let [programShiftRes, scheduleDayRes] = await Promise.all([ProgramService.getShifts(), ProgramService.getScheduleDays()]);

    setProgramShift(programShiftRes);
    setScheduleDays(scheduleDayRes);
    setLoading(false);
  };

  const getShiftList = () => {
    return (
      <ul className="list-group">
        {programShifts
          ?.filter((x) => x.programId == props.selectedProgram.id)
          .map((shift: ProgramShift, index) => (
            <li key={shift.id} className="startdate list-group-item list-group-item-action border-bottom">
              <div className="date">
                <p>{shift.name}</p>
              </div>

              <ActionIcon label="Delete" tooltip="Delete" icon={<IconReject />} color="danger" extraClass="custom-delete-btn" onClick={() => handleDeleteShift(index)} />
            </li>
          ))}
      </ul>
    );
  };

  const handleAddShift = async (event: any) => {
    event.preventDefault();
    let name = event.target.name.value;
    let programId = props.selectedProgram.id;

    if (programId != null && name != '') {
      setLoading(true);
      let payload = { name, programId } as ProgramShift;
      let response = await ProgramService.addShift(payload);
      setLoading(false);

      event.target.reset();
      var temp = [...programShifts];
      temp.push(response);
      setProgramShift(temp);
    }
  };

  const handleDeleteShift = async (index: number) => {
    setLoading(true);
    let shiftId = programShifts[index].id as string | number;
    let response = await ProgramService.removeShift(shiftId);
    setLoading(false);

    var temp = [...programShifts];
    temp.splice(index, 1);
    setProgramShift(temp);
  };

  const getSchedule = () => {

    if(isLoading) return <></>

    if (props.selectedProgram?.id) {
      // saved schedule days
      let displayDays = [...scheduleDays].filter((day: ScheduleDay) => day.programId === props.selectedProgram.id);

      // edited changes should override saved schedule days
      for(let i = 0; i < editedScheduleDays.length; i++) {
        let existing = displayDays.find((x) => x.day === editedScheduleDays[i].day);        
        if(existing) {
          updateScheduleDay(existing, editedScheduleDays[i]);
        } else {
          displayDays.push(editedScheduleDays[i]);
        }
      }
      
      // add missing days to form the week
      for (let i = 0; i < 7; i++) {
        if (!displayDays.find(x => x.day == i)) {
          displayDays.push({ day: i, active: false } as ScheduleDay);
        }
      }

      displayDays = displayDays.sort((a, b) => a.day - b.day);
     

      return (
        <>
          {displayDays.map((sDay: ScheduleDay, index) => (
            <div key={sDay.day} className="row row-xs pr-2">
              <div className="col-sm d-flex">
                <div className="form-check d-flex align-items-center pl-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="hasReminder"
                    name="hasReminder"
                    checked={sDay.active}
                    onChange={(e) => {
                      scheduleChangeValue('active', e.target.checked, sDay.day);
                    }}
                  ></input>
                </div>
                <p>{Days[sDay.day]}: </p>
              </div>
              <div className="col-sm">
                <input
                  type="time"
                  className="form-control form-control-sm"
                  defaultValue={sDay?.fromTime}
                  onChange={(e) => {
                    scheduleChangeValue('fromTime', e.target.value, sDay.day);
                  }}
                />
              </div>
              <p className="to">to</p>
              <div className="col-sm">
                <input
                  type="time"
                  className="form-control form-control-sm"
                  defaultValue={sDay?.toTime}
                  onChange={(e) => {
                    scheduleChangeValue('toTime', e.target.value, sDay.day);
                  }}
                />
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  const scheduleChangeValue = (field: string, value: any, day: any) => {
    // add mofification to the editedScheduleDays to be saved later
    const copy = [...editedScheduleDays];
    let existingScheduleDay: any = copy.find((item) => item.day === day);
    if (existingScheduleDay) {
      existingScheduleDay[field] = value;
    } else {
      let currentScheduleDay = { day: day } as any;
      currentScheduleDay[field] = value;
      copy.push(currentScheduleDay);
    }

    setEditedScheduleDays(copy);
  };

  const updateScheduleDay = (existingDay: ScheduleDay, newDay: ScheduleDay) => {
    existingDay.active = newDay.active;
    existingDay.fromTime = newDay.fromTime;
    existingDay.toTime = newDay.toTime    
  }

  const saveScheduleChanges = async () => {
    if (editedScheduleDays.length > 0) {
      setLoading(true);

      let resp = await ProgramService.updateScheduleDays(editedScheduleDays, props.selectedProgram.id as number, props.selectedSchool.id);
      setLoading(false);

      if (resp?.status === HttpStatusCode.Ok) {
        let copy = [...scheduleDays];
        resp.data.forEach((item: ScheduleDay) => {
          const existing = copy.find((sDay) => sDay.day === item.day);
          if(existing)
            updateScheduleDay(existing, item);
        });
        setEditedScheduleDays([]);
        setScheduleDays(copy);
      }
    }
  };

  useEffect(() => {
    if (!props.selectedSchool) return;
    setEditedScheduleDays([]);
    loadData();
  }, [props.selectedSchool, props.selectedProgram]);

  return (
    <div className="card-schedule">
      {props.selectedSchool?.id == SchoolID.PA ? (
        <div className="card startdates">
          <div className="card-header startdate-header">
            <h5>Shifts</h5>
          </div>
          <div className="card-body overflow-auto">
            {!props.selectedProgram?.id && <p className="no-data">Select a program to see/modify it shifts.</p>}
            {getShiftList()}
          </div>
          <div className="card-footer">
            <h6>Register shift</h6>
            <div className="custom-form">
              <form onSubmit={handleAddShift}>
                <div className="input-wrapper">
                  <label htmlFor="date">Shift :</label>
                  <input type="text" className="form-control form-control-sm" name="name" />
                </div>
                <button type="submit" className="btn btn-sm btn-primary custom-form-btn">
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="card startdates">
          <div className="card-header startdate-header">
            <h5>Schedule</h5>
          </div>
          <div className="card-body schedule">
            {!props.selectedProgram?.id && <p className="no-data">Select a program to see/modify it schedule.</p>}
            {/* will have: id, school_id, program_id, bool, day, from, to */}
            {getSchedule()}
          </div>
          <div className="card-footer d-flex justify-content-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                saveScheduleChanges();
              }}
            >
              Save Schedule
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardScheduleView;
