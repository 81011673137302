import ActionIcon from "../action-icon/actionIcon";
import { Edit3 as IconEdit, CheckCircle as IconAccept, XCircle as IconCancel } from "react-feather";

export interface optionsCellWithEditParams {
  item: any;
  content?: any;
  normalPreEdit?: any;
  normalPostEdit?: any;
  editDisabled?: boolean;
  editing?: any;
  onAccept?: (changes: any) => void;
  onCancel?: () => void;
}

/// <summary>
/// Renders a table column with edit icon / accept and cancel
/// </summary>
export const optionsCellWithEdit = ({ item, content, normalPreEdit, normalPostEdit, editing, onAccept, onCancel, editDisabled=true }: optionsCellWithEditParams) => (
  <>
    {!item.row.editing ? normalPreEdit : null}
    {item.row.values.id < 0 ? null : !item.row.editing ? (
      <ActionIcon
        label="Edit"
        tooltip="Edit File Type"
        icon={<IconEdit />}
        color="info"
        disabled={editDisabled}
        onClick={() => {
          item.row.editing = true;
          item.rerender();
        }}
      />
    ) : (
      <>
        <ActionIcon
          label="Accept"
          tooltip="Accept changes"
          icon={<IconAccept />}
          color="success"
          onClick={() => {
            item.row.editing = false;
            item.rerender();
            const rowChanges = item.getChanges(item.row);
            onAccept?.(rowChanges);
          }}
        />

        <ActionIcon
          label="Reject"
          tooltip="Reject changes"
          icon={<IconCancel />}
          color="danger"
          onClick={() => {
            item.row.editing = false;
            item.rerender();
          }}
        />
      </>
    )}

    {content}

    {item.row.editing ? editing : null}
    {!item.row.editing ? normalPostEdit : null}
  </>
);
