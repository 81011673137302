import { useEffect, useState } from "react";
import { PrevFormProps } from "../../../hooks/prevForms";
import { SignedPageData } from "../../../viewControllers/signedPage";
import usePageSignature from "../../../hooks/pageSignature";
import { SignedForm } from "../../../models/Forms.model";
import { SDGKUProgram } from "../../../models/Enums";
import { useForm } from "react-hook-form";
import dateService from "../../../services/date.service";
import { SDGKUFormsPage, SignedFormKey } from "../../../models/Forms.enum";
import signedFormService from "../../../services/signedForm.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import TextField from "../../shared/Input/textField";
interface ExtraData {
	revisedDate: string;
	transferCredits: string;
	sdgkuCredits: string;
	pricePerCredit: string;
	tuition: number;
	fees: number;
	loaBegin: string;
	loaEnd: string;
}

interface AddendumPrevForm {
	studentName: string;
	endDate: string;
    startDate: string;
	extraData: ExtraData;
}

const AddendumPrev = ({ student, closeModal, processResponse }: PrevFormProps) => {

	const [viewController, setViewController] = useState<SignedPageData>(null!);
	const [isEnableSubmit, setIsEnableSubmit] = useState(false);
	const { customSavePrevForm, getSignature, setIsCustomValid, setRequiredCustomValidation, signatureData } = usePageSignature(viewController);
	const [extraData, setExtraData] = useState<ExtraData>({} as ExtraData);

	const {
		register,
		formState: { errors, isValid },
		setValue,
		trigger,
	} = useForm<AddendumPrevForm>();

	const initializeViewController = (formData: SignedForm, program: SDGKUProgram) => {
		let programStartDate = "Loading...";
        let programEndDate = "Loading...";

		if (formData?.programStart) {
            programStartDate = dateService.formatYMD(formData.programStart);
        }

        if (formData?.programEnd) {
            programEndDate = dateService.formatYMD(formData.programEnd);
        }
		setValue("startDate", programStartDate);
        setValue("endDate", programEndDate);
		setValue("studentName", `${formData.student.firstName} ${formData.student.lastName}`);
		setRequiredCustomValidation(true);
		setViewController(new SignedPageData(SignedFormKey.ADDENDUM, program, formData, "", setIsEnableSubmit));
	};

	const loadForm = async (studentId: number) => {
		const resp = await signedFormService.getFormDataForPrevForm(studentId, SignedFormKey.ADDENDUM);
		if (resp?.status === HttpStatusCode.Ok) {
			const program: SDGKUProgram = resp.data.programId;
			initializeViewController(resp?.data, program);
		}
	};

	const loadInitialData = async () => {
		const sudentId = student.id
		if (sudentId) {
			loadForm(+sudentId);
		}
	};

	const manualValudation = async () => {
		if (signatureData) {
			const isItValidNow = await trigger();
			if (!isItValidNow) {
				// start a timer that will trigger the validation every 1 second
				setInterval(() => {
					trigger();
				}, 1000);
			}
		}
	}

	const handleInputChange = (e: any) => {
		setExtraData({ ...extraData, [e.target.name]: e.target.value });
		trigger([e.target.name]);
		setIsCustomValid(isValid);
	}

	const submitForm = async () => {
		let data = { ...extraData };
		const res = await customSavePrevForm(SDGKUFormsPage.Addendum, data);
		processResponse(res);
		closeModal();
	};

	useEffect(() => {
		loadInitialData();
	}, []);

	useEffect(() => {
		// validate the forms fields when the signature changes
		manualValudation();
	}, [signatureData]);


	return (
		<div className="px-5">
			<div className="row">
				<div className="col-6">
					<TextField disabled errors={errors} label="Student Name" field="studentName" type="text" register={register} />
				</div>
				<div className="col-3">
					<TextField disabled errors={errors} label="Original Start date" field="startDate" type="text" register={register} />
				</div>
				<div className="col-3">
					<TextField disabled errors={errors} label="Original end date" field="endDate" type="text" register={register} />
				</div>
			</div>
			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="Revised End Date" field="revisedDate" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>
			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="Transfer Credits Accepted (Semester)" field="transferCredits" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>
			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="SDGKU Credits to Graduate (Semester)" field="sdgkuCredits" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>
			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="Revised Price per Credit $" field="pricePerCredit" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>

			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="Revised Tuition $" field="tuition" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>

			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="Revised Fees $" field="fees" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>

			<br />
			<h5 className="sub-title">LEAVE OF ABSENCE (LOA)</h5>

			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="LOA Begins" field="loaBegin" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>
			<div className="row">
				<div className="">
					<TextField rules={{ required: true }} errors={errors} label="LOA Ends" field="loaEnd" type="text" register={register} onChange={handleInputChange} />
				</div>
			</div>

			<div className="container text-center">
				<div className="">
					<div className="pad">{getSignature()}</div>
				</div>
			</div>

			<div className="text-center pb-2 mb-3 mt-3">
				<button disabled={!isEnableSubmit} className="btn btn-primary" type="submit" onClick={submitForm}>
					Save and send
				</button>
			</div>

		</div>

	);
}

export default AddendumPrev;
