import { useContext, useEffect, useState } from "react";
import { Student } from "../../../../models/Student.model";
import globalContext from "../../../../context/globalContext";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import usePageSignature from "../../../../hooks/pageSignature";
import { useForm } from "react-hook-form";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { COBAFormsPage, SignedFormKey } from "../../../../models/Forms.enum";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import { getProgramData } from "../../sdgku/sdgkuController";
import { SignedForm } from "../../../../models/Forms.model";
import { COBAProgram } from "../../../../models/Enums";
import TextField from "../../../../components/shared/Input/textField";
import "./cobaOrientation.scss";


interface ExtraData {
  // add here necessary extra data
  orientation_date: string;
  key: string;
  smock_size: string;
}

interface OrientationForm {
  student: Student;
  extraData: ExtraData;
}

const OrientationForm = (props: any) => {
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);
  const [viewController, setViewController] = useState<SignedPageData>(null!);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { saveForm, getSignature, setIsCustomValid, getAcknowledge, setRequiredCustomValidation, signatureData } = usePageSignature(viewController);
  const [tokenError, setTokenError] = useState(false);
  const [completed, setCompleted] = useState(false);

  const { register, formState: { errors, isValid }, setValue, getValues } = useForm<OrientationForm>();

  const loadInitalData = async () => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    const studentId = params.get('id');

    if (token && studentId) {
      loadForm(+studentId, token);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    };
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    try {
      const resp = await signedFormService.getFormData(studentId, SignedFormKey.ORIENTATION_FORM, token);
      if (resp?.status == HttpStatusCode.Ok) {
        const programId = resp.data.programId;
        initializeViewController(resp.data, programId, token);
      }
    } catch {
      showAlert('Error loading form', AlertType.Error);
      setTokenError(true);
    } finally {
      setLoading(false);
    };
  }

  const initializeViewController = (formData: SignedForm, programId: number, token: string) => {
    if (!formData) return;

    setValue('student.firstName', formData.student.firstName + " " + formData.student.lastName);
    setViewController(new SignedPageData(SignedFormKey.ORIENTATION_FORM, programId, formData, token, setEnableSubmit));
  }

  const submitForm = async () => {
    if (await saveForm(COBAFormsPage.OrientationForm, true, getValues('extraData'))) setCompleted(true);
    else showAlert('Error while saving. Please verify all fields and try again', AlertType.Error);
  }

  useEffect(() => {
    loadInitalData();
  }, []);

  const pageContent = () => {
    return (
      <div className="content">
        <div className="form-header">
          <div className="school-information">
            <div className="col-2">
              <img src="/static/coba/logo-coba.png" alt="coba logo" />
            </div>
            <div className="col-3">
              <p className="text-center">
                COBA Academy <br />
                663 N. Euclid St., Anaheim, CA 92801 | (714) 533-1400 | www.coba.edu <br />
              </p>
            </div>
          </div>
          <h3 className="form-title text-center">Orientation</h3>
        </div>
        <hr />
        <div className="form-body">

          <div className="row">

            <div className="col-6">
              <TextField
                label="Date of Orientation"
                field="extraData.orientation_date"
                register={register}
                errors={errors}
                type="date"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextField
                disabled
                label="Student Name"
                field="student.firstName"
                register={register}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-4">
              <TextField
                label="Key #"
                field="extraData.key"
                register={register}
                errors={errors}
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextField
                label="T-Shirt or Smock Size"
                field="extraData.smock_size"
                register={register}
                errors={errors}
                type="text"
              />
            </div>
          </div>
          <hr />
          <div className="table-information">
            <div className="topics">
              <h3 className="section-title">Topics discussed in Orientation</h3>
              <ul>
                <li>Classroom Expectations</li>
                <li>First Day of School</li>
                <li>Cancellation Policy</li>
                <li>Uniform Policy</li>
                <li>Education Program</li>
                <li>Grading Rubric</li>
                <li>Receptionist Area</li>
                <li>Service Menu & Coupons + Client Services</li>
                <li>Material Safety Data Sheet</li>
                <li>Guest Vision - Time Clock</li>
                <li>Student Portal & Posting of Hours</li>
                <li>15-Minute Grace Period</li>
                <li>Missing School</li>
                <li>14-day Absent Policy</li>
                <li>Leave of Absence</li>
                <li>Cell Phones</li>
                <li>Student Lunch & Breaks Schedule</li>
                <li>Satisfactory Academic Progress</li>
                <li>Maximum Timeframe</li>
                <li>Additional Charges - Over Contract Fees</li>
                <li>Makeup Hour Policy</li>
                <li>State board Application</li>
                <li>Academy Library</li>
                <li>Emergency School Evacuation Procedure</li>
                <li>Parking Lot</li>
                <li>Graduation Day</li>
                <li>Certificate of Completion & Proof of Training</li>
                <li>Monthly School's Calendar</li>
                <li>Counseling for Resources for Students</li>
                <li>Voter Registration Forms</li>
                <li>Holidays - School Closed</li>
                <li>Meet the Team</li>
              </ul>
            </div>
            <div className="documents">
              <h3 className="section-title documents-section">Additional Documents:</h3>
              <ul className="docs">
                <li className="star">School Catalog</li>
                <li className="star">Curriculum Schedule</li>
                <li className="star">Orientation Student Packet</li>
                <li className="star">Classroom Expectations & Education Program</li>
                <li className="star"><p className="addit-doc">Addition Documents</p>
                  <ul>
                    <li>Classroom Schedules</li>
                    <li>Course Outlines</li>
                    <li>Graduation Checklist</li>
                    <li>Timecard</li>
                    <li>Grade Form</li>
                    <li>Student Portal</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="acknowledgments">
            <div className="ack-1 ack">
              <p className="ack-title">
                Classroom Expectation Acknowledgment
              </p>
              {getAcknowledge('ack-1', 'I acknowledge, and I understand the Classroom Expectations and Educational Program at Coba Academy.')}
            </div>
            <div className="ack-2 ack">
              <p className="ack-title">
                Orientation Attendance Acknowledgment
              </p>
              {getAcknowledge('ack-2', 'I acknowledge I attended orientation before my first day of class and received the information above.')}
            </div>
          </div>
          <hr />
          <div className="signature">
            <h3 className="signature-title">Student Signature</h3>
            {getSignature()}
          </div>
        </div>
        <div className="form-footer">
          <div className="submit-button">
            <button
              className="btn btn-primary"
              onClick={submitForm}
              disabled={!enableSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="orientation-form">
      <div className="doc-content pt-4">
        {tokenError ? <TokenError /> : null}
        {completed ? <Completed /> : null}
        {!tokenError && !completed ? pageContent() : null}
      </div>
    </div>
  );
};

const TokenError = () => {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">Invalid token</h4>
      <p>The token is invalid or expired. Please request a new one.</p>
    </div>
  );
}

const Completed = () => {
  return (
    <div className="alert alert-success mx-5" role="alert">
      <h4 className="alert-heading">Form completed</h4>
      <p>Thank you for attending COBA Academy's
        Orientation Form! </p>
    </div>
  );
}

export default OrientationForm;