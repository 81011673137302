import { useContext } from 'react';
import globalContext from '../context/globalContext';
import { Permission } from '../models/Enums';
import { useHistory } from 'react-router-dom';

const usePermissionRequired = (permissions: Permission[]) => {
  const { hasPermission } = useContext(globalContext);
  const history = useHistory();
  const redirectPath = '/NotAllowed';

  for (let i = 0; i < permissions.length; i++) {
    if (!hasPermission(permissions[i])) {
      history.push(redirectPath);
    }
  }

  return true;
};

export default usePermissionRequired;
