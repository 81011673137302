import './thankYouPage.scss';

const SCCBBThankYouPage = (props: any) => {
	return (
		<>
			<div className="thank-you-container">
				<h1>Thank you for your application</h1>
				<h4>An administrator will reach out to you shortly.</h4>
			</div>
		</>
	);
};
export default SCCBBThankYouPage;
