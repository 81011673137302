import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import Modal from '../../modal/modal';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { UploadRequest } from '../../../models/uploadRequest.model';
import uploadRequestService, { UploadRequestGenerationType } from '../../../services/uploadRequest.service';
import studentService from '../../../services/student.service';
import TextField from '../../shared/Input/textField';

import './createUploadRequestModal.scss';
import { Student } from '../../../models/Student.model';
import globalContext from '../../../context/globalContext';
import emailTemplateService from '../../../services/emailTemplate.service';
import { EmailType, SchoolID } from '../../../models/Enums';
import { toast } from 'react-toastify';
import Collapsible from '../../shared/collapsible/collapsible';
import moment from 'moment';
import { PATTERNS } from '../../shared/Input/patterns';
import SearchBox from '../../shared/searchBox/searchBox';
import { Mail as IMail, Clipboard as IClipboard } from 'react-feather';
import copy from 'copy-to-clipboard';

interface ICreateUploadRequestModalProps {
  visible: boolean;
  studentId: number | string;
  onClose?: () => void;
  showStudentSearch?: boolean;
  onSend?: () => void;
}

interface InternalFileInfo {
  fileType: string;
  fileTypeId: number;
}

const CreateUploadRequestModal: FunctionComponent<ICreateUploadRequestModalProps> = ({ visible, studentId, onClose, showStudentSearch, onSend }) => {
  const { setLoading, loaders, user } = useContext(globalContext);
  const [docTypeOptions, setDocTypeOptions] = useState([] as any);
  const [student, setStudent] = useState({} as Student);
  const [selectedFiles, setSelectedFiles] = useState([] as InternalFileInfo[]);
  const [allowSave, setAllowSave] = useState(true);
  const [studentSearch, setStudentSearch] = useState() as any;
  const [studentOptions, setStudentOptions] = useState([] as Student[]);
  const [showOptions, setShowOptions] = useState(false);
  const [generationType, SetGenerationType] = useState(UploadRequestGenerationType.Send);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<UploadRequest>();

  const loadDocTypes = async () => {
    const docTypes = await loaders.documentTypes();

    setDocTypeOptions(docTypes.map((x: any) => ({ value: x.id, label: x.name })).sort((a, b) => (a.label < b.label ? -1 : 1)));
  };

  const loadStudentInfo = async () => {
    if (!studentId) return;

    setLoading(true);
    const studentResp = await studentService.getStudentById(studentId);
    setLoading(false);

    if (studentResp?.status === HttpStatusCode.Ok) {
      let data = studentResp.data;
      setStudent(data);
      loadDefaultValues(data);
    }
  };

  const selfClose = () => {
    setAllowSave(true);
    setStudent({} as Student);
    onClose && onClose();
  };

  const loadDefaultValues = async (student: Student) => {
    setValue('to', student.email);
    // the school notify emails are in student.schoolData.notifyToEmails
    setValue('cc', user.email);
    setValue('replyTo', user.email);
    setValue('dueDate', moment().add(7, 'day').endOf('day').format('YYYY-MM-DD'));

    let res = await emailTemplateService.getTemplateSubject(EmailType.DOCUMENT_UPLOAD_REQUEST, student.school);
    if (res?.status === HttpStatusCode.Ok) {
      setValue('subject', res.data.subject);
    }
  };

  const checkKeyDown = (e: any) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const selectChanged = (e: any) => {
    setSelectedFiles(e.map((x: any) => ({ fileType: x.label, fileTypeId: x.value })));
    setValue(
      'requestedFileTypes',
      e.map((x: any) => x.value)
    );
  };

  const handleSend = async (form: any) => {
    setAllowSave(false);

    form.studentId = studentId;
    if (showStudentSearch) {
      form.studentId = student.id;
    }
    const response = await uploadRequestService.generateUploadRequest(form, generationType);
    if (response?.status === HttpStatusCode.Ok) {
      resetModal();
      isSend() ? showSentToast() : copyLinkToClipboard(response);
    } else {
      handleError(response);
    }
  };

  const resetModal = () => {
    setAllowSave(false);
    selfClose();
    onSend?.();
  };

  const showOkToast = (title: string) => {
    toast.dark(title, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const handleError = (response: any) => {
    toast.error('❌ Error Creating Request', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });

    console.error(response);
  };

  const showSentToast = () => {
    showOkToast('Upload Request Sent');
  };

  const copyLinkToClipboard = (response: any) => {
    if (response.status === HttpStatusCode.Ok && response.data?.status === 'success') {
      copy(response.data?.link);
      showOkToast('Link Copied to Clipboard');
    } else {
      toast.error('❌ Error Creating Request', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const handleSearchChange = (text: string) => {
    setStudentSearch(text);
  };

  const searchStudents = async () => {
    const studentReq = await studentService.getStudentsByName(studentSearch);
    if (studentReq?.status === HttpStatusCode.Ok) {
      setStudentOptions(studentReq.data);
      setShowOptions(true);
    }
  };

  const handleSelectStudent = (id: any) => {
    const student = studentOptions.find((s: Student) => s.id === id);
    if (student != undefined) {
      setStudent(student);
      setStudentSearch('');
      loadDefaultValues(student);
    }
    setShowOptions(false);
  };

  const getGenerationTypeButtonClass = (buttonType: UploadRequestGenerationType) => {
    return buttonType === generationType ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary';
  };

  const isSend = () => generationType === UploadRequestGenerationType.Send;

  const body = () => {
    return (
      <form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="new-upload-request-form">
        <div className="generation-type">
          <h6>What do you want to do?</h6>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <button type="button" onClick={() => SetGenerationType(UploadRequestGenerationType.Send)} className={getGenerationTypeButtonClass(UploadRequestGenerationType.Send)}>
              <IMail className="mr-2" />
              Send Email to Student
            </button>

            <button type="button" onClick={() => SetGenerationType(UploadRequestGenerationType.GenerateLink)} className={getGenerationTypeButtonClass(UploadRequestGenerationType.GenerateLink)}>
              <IClipboard className="mr-2" />
              Get the Link to Share
            </button>
          </div>
        </div>

        {(student.firstName || student.lastName) && (
          <h5>
            <span>Student:</span>
            {student.firstName} {student.lastName}
          </h5>
        )}

        {showStudentSearch && (
          <div style={{ margin: '5px 0 15px 0' }}>
            <SearchBox label="Search Student" value={studentSearch} placeholder="Search students by name" onChange={handleSearchChange} onSearch={searchStudents} />
            {showOptions ? (
              <div className="options">
                {studentOptions.map((s: Student) => (
                  <div key={s.id} className={`selectable-student ${student.id === s.id ? 'active' : null}`} onClick={() => handleSelectStudent(s.id)}>
                    <p>{s.firstName + ' ' + s.lastName}</p> <p className="school">{SchoolID[s.school]}</p> <p>{s.email}</p>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}

        {isSend() && (
          <TextField
            field="to"
            type="text"
            label="Email to"
            placeholder="Enter the student email address"
            register={register}
            errors={errors}
            rules={{
              required: isSend(),
              pattern: PATTERNS.emailRegEx,
            }}
          />
        )}

        <TextField field="subject" type="text" label={isSend() ? 'Email Subject' : 'Subject'} placeholder="Enter the subject" register={register} errors={errors} rules={{ required: true }} />

        {isSend() && (
          <TextField
            field="cc"
            type="text"
            labelClass="d-block position-relative"
            label="When uploaded, notify to"
            labelAfter={<span className="text-muted label-after-right">example: jane@mail.com, joe@mail.com</span>}
            placeholder="example: mail1@test.com, mail2@test.com"
            register={register}
            errors={errors}
          />
        )}

        <div className="form-group">
          <label className="bold">
            <span className="required">*</span>File(s) to be requested:
          </label>
          <Select options={docTypeOptions} isMulti={true} className="select" onChange={selectChanged} />
        </div>

        <TextField field="dueDate" type="date" label="Request link due date" placeholder="When the request is due" register={register} errors={errors} rules={{ required: true }} />

        {isSend() && (
          <>
            <TextField field="body" type="text" multiLine={true} label="Message signature" register={register} errors={errors} />

            <Collapsible text="Advanced options">
              <TextField field="replyTo" type="text" placeholder="Email to send replies to" label="Send student replies to" register={register} errors={errors} />
            </Collapsible>
          </>
        )}
      </form>
    );
  };

  useEffect(() => {
    loadDocTypes();
  }, []);

  useEffect(() => {
    loadStudentInfo();
  }, [studentId]);

  return (
    <Modal
      body={body()}
      bodyTitle={<h5>Request to Upload Documents</h5>}
      bodySubtitle="Create a request for student to upload documents"
      size="lg"
      visible={visible}
      flyout={true}
      mainButtonText={isSend() ? 'Send Request' : 'Generate Link'}
      mainButtonDisabled={!allowSave}
      mainButtonClass="btn btn-primary"
      mainButtonIcon={isSend() ? <IMail className="mr-2" /> : <IClipboard className="mr-2" />}
      mainClass="modal-new-upload-request"
      onCancel={selfClose}
      onClose={selfClose}
      onAccept={handleSubmit((d) => handleSend(d))}
    />
  );
};

export default CreateUploadRequestModal;
