import http from "./http-common";

class EmailService {
  async sendStudentEmail(email: any) {
    try {
      let response = await http.post("/communication/email/to_school/", email);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while sending Email");
      return undefined;
    }
  }

async sendUserEmail(email: any) {
  try {
    let formData = new FormData();
    formData.append("student_id", email.student_id);
    formData.append("to", email.to);
    formData.append("subject", email.subject);
    formData.append("message", email.message);
    formData.append("cc", email.cc);


    if (email.files?.length) {
      email.files.forEach((file: any) => {
        formData.append("files", file);
      });
    }

    let response = await http.post("/communication/email/to_student/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    http.fa360.displayError("Unexpected Error occurred while sending Email");
    console.error("Error sending email:", error);
    return undefined;
  }
}

  

  async validateEmailToken(token: string) {
    try {
      let response = await http.post("/communication/email/validate_email_token/", { token });
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while validating token");
      return undefined;
    }
  }

  async getUnreadEmails() {
    try {
      let response = await http.get("/communication/email/get_unread_messages/");
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while get Unread Emails");
      return undefined;
    }
  }

  async getEmails(studentId: number) {
    try {
      let response = await http.get(`/communication/email/get_messages/${studentId}/`);
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occured while getting Emails");
      return undefined;
    }
  }

  async readMessage(emailId: number) {
    try {
      let response = await http.patch(`/communication/email/read_message/${emailId}/`, {
        is_read: true 
      });
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occurred while posting read message");
      return undefined;
    }
  }

  async markAllAsRead(studentId: number) {
    try {
      const response = await http.patch(`/communication/email/mark_all_as_read/${studentId}/`);
      return response;
    } catch (error) {
      console.error("Unexpected Error occurred while marking all emails as read:", error);
      return undefined;
    }
  }

  async getUsersEmailsBySchool(schoolIds: number[]) {
    try {
      const response = await http.get('/communication/email/get_emails/', {
        params: { school_ids: schoolIds.join(',') } 
      });
      return response;
    } catch (error) {
      http.fa360.displayError("Unexpected Error occurred while getting Emails");
      return undefined;
    }
  }
}

export default new EmailService();
