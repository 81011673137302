import { PasswordResetModal } from './components/auth/passwordResetModal';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Spinner } from './components/shared/loader/spinner';
import { ToastContainer } from 'react-toastify';

import ChangePassword from './pages/auth/change-password/changePassword';
import Coba from './pages/preapplication-admission/coba/coba';
import COBAThankYouPage from './pages/preapplication-admission/coba/thankYou/thankYouPage';
import SCCBBThankYouPage from './pages/preapplication-admission/sccbb/thankYou/thankYouPage';
import CreateDocumentTemplate from './pages/documentTemplate/createDocumentTemplate';
import DocumentChecklistPage from './pages/documentChecklistPage/documentChecklistPage';
import DocumentTypePage from './pages/documentChecklistPage/documentTypePage';
import CalendarPage from './pages/calendar/calendarPage';
import Footer from './components/footer/footer';
import GlobalAlert from './components/global-alert/globalAlert';
import GlobalState from './context/globalState';
import Home from './pages/home/home';
import Login from './pages/auth/login/login';
import NavBar from './components/navBar/navBar';
import PasswordRecovery from './pages/auth/recovery/recovery';
import PendingApplicationRequest from './pages/students/application-request/pendingApplicationRequest';
import PreApplicationRequest from './pages/preapplication-admission/sccbb/preApplicationRequest';
import PrivateRoute from './components/router/private-route';
import ProgramsPage from './pages/programs/programsPage';
import PureAesthetics from './pages/preapplication-admission/pureAesthetics/pureAesthetics';
import SanDiegoGlobal from './pages/preapplication-admission/sdgku/sanDiegoGlobal';
import SanDiegoGlobal2 from './pages/preapplication-admission/sdgku/sanDiegoGlobal2';
import SDGKUThankYouPage from './pages/preapplication-admission/sdgku/thankYou/thankYouPage';
import StudentCreate from './pages/students/create/studentCreate';
import StudentList from './pages/students/list/studentList';
import StudentOverview from './pages/students/overview/studentOverview';
import StudentQuickCreate from './pages/students/quick-create/studentQuickCreate';
import UploadRequest from './pages/students/upload-request/uploadRequest';
import UploadRequestedDocuments from './pages/students/upload-requested-documents/uploadRequestedDocuments';
import UserProfile from './pages/auth/profile/userProfile';
import ThankYouPageQuestionaire from './pages/preapplication-admission/pureAesthetics/thankYouQuestionaire';

import './dashforge.css';
import './App.scss';
import 'bootstrap/dist/js/bootstrap.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Consent from './components/consent/consent';
import CobaDisclosure from './pages/forms/coba/disclosure/cobaDisclosure';
import SDGKUDisclosure from './pages/forms/sdgku/disclosure/sdgkuDisclosure';
import FeePage from './pages/programs/feePage';
import { Factsheet } from './pages/forms/sdgku/factsheet';
import { Enrollment } from './pages/forms/sdgku/enrollment';
import { VIB } from './pages/forms/sdgku/vib';
import { CobaFactsheetPage } from './pages/forms/coba/factsheet/cobaFactsheetPage';
import { SignedFormKey } from './models/Forms.enum';
import DocumentTemplatePage from './pages/documentTemplate/documentTemplatePage';
import StudentFinancialEstimate from './pages/students/financial-estimate/studentFinancialEstimate';
import PermissionNotAllowed from './pages/auth/permissionNotAllowed/permissionNotAllowed';
import ErrorBoundary from './components/shared/errorBoundary/errorBoundary';
import EnrollmentWizard from './pages/forms/sdgku/enrollmentWizard/enrollmentWizard';
import TranscriptsCommitment from './pages/forms/sdgku/transcriptsCommitment/transcriptsCommitment';
import AutoLogout from './hooks/autoLogout';
import inviteUserPage from './components/calendar/inviteUser/inviteUserPage';
import InviteCreateUser from './components/calendar/inviteUser/inviteCreateUser';
import Unsubscribe from './pages/forms/unsubscribe';
import PeriodsPage from './pages/programs/periodsPage';
import { FinancialAid } from './pages/forms/coba/financialAid';
import HybridDisclosure from './pages/forms/sdgku/hybridDisclosure/sdgkuHybridDisclosure';
import BenefitCoding from './pages/forms/sdgku/benefitCoding/sdgkuBenefitCoding';
import { VaEducationBenefits } from './pages/forms/sdgku/vaEducationBenefits';
import { TranscriptionEvaluation } from './pages/forms/sdgku/transcript_evaluation';
import OrientationForm from './pages/forms/coba/orientation/coba.orientation';
import StudentEmail from './pages/emails/student-emails/studentEmail';
import IpadDisclosure from './pages/forms/coba/disclosure/disclosureIpad';
import SpanishEnrollment from './pages/forms/sdgku/enrollment/sdgku.enrollment.spanish';
import Addendum from './pages/forms/sdgku/addendum/sdgkuAddendum';
import { ExitCounseling } from './pages/forms/coba/exitCounseling/coba.exitCounseling.index';
import DisabilityButton from './components/disability/disabilityButton';
import AddendumSpanish from './pages/forms/sdgku/addendum/sdgkuAddendumSpanish';
import ExitCounselingSCCBB from './pages/forms/sccbb/exitCounseling/ExitCounselingSCCBB';

function App() {
  const location = useLocation();

  function getHeader(currentPath: string) {
    return !currentPath.includes('public/') ? <NavBar></NavBar> : null;
  }

  function getFooter(currentPath: string) {
    return !currentPath.includes('public/') ? <Footer></Footer> : null;
  }

  function getAutoLogout(currentPath: string) {
    return !currentPath.includes('public/') ? <AutoLogout /> : null;
  }

  function showDisabilityButton(currentPath: string) {
    return currentPath.includes('public/') ? <DisabilityButton /> : null;
  }

  return (
    <GlobalState>
      <div className="App-Container">
        {getHeader(location.pathname)}

        <div className="container-fluid">
          <GlobalAlert />
          <ToastContainer />
          <Spinner />
          <PasswordResetModal />
          <Consent />
          {getAutoLogout(location.pathname)}

          {showDisabilityButton(location.pathname)}

          {/* <AppRouter />  NOT READY YET, we need to upgrade react-router-dom*/}

          <ErrorBoundary>
            <PrivateRoute exact path="/admin/application-requests" component={PendingApplicationRequest} />
            <PrivateRoute exact path="/admin/dashboard" component={Home} />
            <PrivateRoute exact path="/admin/document-checklists" component={DocumentChecklistPage} />
            <PrivateRoute exact path="/admin/document-types" component={DocumentTypePage} />
            <PrivateRoute exact path="/admin/document-templates" component={DocumentTemplatePage} />
            <PrivateRoute exact path="/admin/home" component={Home} />
            <PrivateRoute exact path="/admin/programs" component={ProgramsPage} />
            <PrivateRoute exact path="/admin/upload-requests" component={UploadRequest} />
            <PrivateRoute exact path="/admin/upload-requests/:studentId/:newRequest?" component={UploadRequest} />
            <PrivateRoute exact path="/admin/programs/periods" component={PeriodsPage} />

            <PrivateRoute exact path="/events/calendar" component={CalendarPage}></PrivateRoute>
            <PrivateRoute exact path="/school/create-template" component={CreateDocumentTemplate} />
            <PrivateRoute exact path="/settings/profile" component={UserProfile} />

            <PrivateRoute exact path="/student/create" component={StudentCreate} />
            <PrivateRoute exact path="/student/edit/:id" component={StudentCreate} />
            <PrivateRoute exact path="/student/list" component={StudentList} />
            <PrivateRoute exact path="/student/overview/:id" component={StudentOverview} />
            <PrivateRoute exact path="/student/estimate/:id" component={StudentFinancialEstimate} />
            <PrivateRoute exact path="/student/quick-create" component={StudentQuickCreate} />
            <PrivateRoute exact path="/student/program/fees" component={FeePage} />

            <Route exact path="/public/logout" component={Login} />
            <Route exact path="/public/login" component={Login} />
            <Route exact path="/public/password-recovery" component={PasswordRecovery} />
            <Route exact path="/public/password-change/" component={ChangePassword} />
            <Route exact path="/NotAllowed" component={PermissionNotAllowed} />
            <Route exact path="/public/invite" component={inviteUserPage} />
            <Route exact path="/public/department-invite" component={InviteCreateUser} />
            <Route exact path="/public/unsubs" component={Unsubscribe} />

            <Route exact path="/">
              <Redirect to="/public/login" />
            </Route>

            <Route exact path="/public/socal-barber-beauty" component={PreApplicationRequest} />
            <Route exact path="/public/sccbb" component={PreApplicationRequest} />
            <Route exact path="/public/sccbb/thank_you" component={SCCBBThankYouPage} />
            <Route exact path="/public/application-request" component={PreApplicationRequest} />
            <Route exact path="/public/application-requests" component={PreApplicationRequest} />
            <Route exact path="/public/pure-aesthetics" component={PureAesthetics} />
            <Route exact path="/public/pure-aesthetics/thankYou" component={ThankYouPageQuestionaire} />
            <Route exact path="/public/coba" component={Coba} />
            <Route exact path="/public/coba/thank_you" component={COBAThankYouPage} />
            <Route exact path="/public/sdgku" component={SanDiegoGlobal} />
            <Route exact path="/public/sdgku/1/:id?" component={SanDiegoGlobal} />
            <Route exact path="/public/sdgku/2/:id?" component={SanDiegoGlobal2} />
            <Route exact path="/public/sdgku/thank_you" component={SDGKUThankYouPage} />
            <Route exact path="/public/upload-request" component={UploadRequestedDocuments} />

            <Route exact path="/public/forms/SDGKU/enrollment_forms" component={EnrollmentWizard} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.FACTSHEET} component={Factsheet} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.ENROLLMENT_AGREEMENT} component={Enrollment} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.ENROLLMENT_AGREEMENT_SPANISH} component={SpanishEnrollment} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.ENROLLMENT_AGREEMENT_SPANISH_2} component={SpanishEnrollment} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.VIB} component={VIB} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.DISCLOSURE} component={SDGKUDisclosure} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.TRANSCRIPTS_COMMITMENT} component={TranscriptsCommitment} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.HYBRID_STATEMENT} component={HybridDisclosure} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.BENEFIT_CODING} component={BenefitCoding} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.VA_EDUCATION_BENEFIT_CERTIFICATION} component={VaEducationBenefits} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.TRANSCRIPT_EVALUATION_REQUEST} component={TranscriptionEvaluation} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.ADDENDUM} component={Addendum} />
            <Route exact path={'/public/forms/SDGKU/' + SignedFormKey.ADDENDUM_SPANISH} component={AddendumSpanish} />

            <Route exact path={'/public/forms/coba/' + SignedFormKey.DISCLOSURE} component={CobaDisclosure} />
            <Route exact path={'/public/forms/coba/' + SignedFormKey.FACTSHEET} component={CobaFactsheetPage} />
            <Route exact path={'/public/forms/coba/' + SignedFormKey.FINANCIAL_AID} component={FinancialAid} />
            <Route exact path={'/public/forms/coba/' + SignedFormKey.ORIENTATION_FORM} component={OrientationForm} />
            <Route exact path={'/public/forms/coba/' + SignedFormKey.IPAD_DISCLOSURE} component={IpadDisclosure} />
            <Route exact path={'/public/forms/coba/' + SignedFormKey.EXIT_COUNSELING} component={ExitCounseling} />

            <Route exact path={'/public/email-reply'} component={StudentEmail} />
            <Route exact path={'/public/forms/sccbb/' + SignedFormKey.EXIT_COUNSELING_SCCBB} component={ExitCounselingSCCBB} />
          </ErrorBoundary>
        </div>

        {getFooter(location.pathname)}
      </div>
    </GlobalState>
  );
}

export default App;
